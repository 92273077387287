import { enhancedStore } from 'app/providers/storeProvider'

import { displayFluctuations } from './displayFluctuations'
import { img_walls_to_PFm_PFc } from './img_walls_to_PFm_PFc'
import { prepareHotmap } from './prepareHotmap'
import { setPFaz } from './setPFaz'
import { setProgressBar } from './setProgressBar'

import { DIMENSION } from '../../../store/DIMENSION'

export const updateWaves = () => {
  const state = enhancedStore.getState()
  if (!DIMENSION.ctx_imgData) return
  const routers = state.equipment?.routers || []
  // ИССЛЕДОВАНИЯ
  if ((DIMENSION.Counter + 2) % 100 === 0) {
    // макисмальный уровень сигнала 150
    for (let i = 0; i < routers.length; i++) {
      const currRouter = routers[i]
      if (currRouter.state === 3) {
        // если кнопка в активной фазе
        DIMENSION.PFvz[DIMENSION.Rx[i]][DIMENSION.Ry[i]] = 20
        DIMENSION.PFm[DIMENSION.Rx[i]][DIMENSION.Ry[i]] = 400
        DIMENSION.PFc[DIMENSION.Rx[i]][DIMENSION.Ry[i]] = 2
      }
    }
  }

  // ===== Работа мозжечковой нейронной сети =====
  for (let x = 1; x < DIMENSION.NImgx + DIMENSION.takeover - 1; x++) {
    for (let y = 1; y < DIMENSION.NImgy + DIMENSION.takeover - 1; y++) {
      const cen = DIMENSION.PFz[x][y]
      setPFaz(x, y, cen)
      prepareHotmap(x, y, cen)
    }
  }

  // neural network processing #2
  for (let x = 1; x < DIMENSION.NImgx + DIMENSION.takeover - 1; x++) {
    for (let y = 0; y < DIMENSION.NImgy + DIMENSION.takeover - 1; y++) {
      DIMENSION.PFz[x][y] += DIMENSION.PFvz[x][y] * DIMENSION.TS
    }
  }
  // ===== Отображение колебаний =====
  displayFluctuations()

  // progress bar
  if (DIMENSION.PCounter >= 0) {
    setProgressBar()
  }
  if (DIMENSION.PCounter % 700 === 0) {
    img_walls_to_PFm_PFc() // отчистка PFz, PFvz, PFaz, PFm и PFc и перезаполенение PFm и PFc
  }
}
