import { popupActions } from 'entities/Popup'
import React, { FC, memo, useCallback } from 'react'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Button } from 'shared/ui/Button'
import { Flex } from 'shared/ui/Flex'
import { Text } from 'shared/ui/Text'

export interface SaveResultSuccessPopupProps {}

const SaveResultSuccessPopup: FC<SaveResultSuccessPopupProps> = () => {
  const dispatch = useAppDispatch()

  const handleClick = useCallback(() => {
    dispatch(popupActions.setIsOpen(false))
    window.location.href = '/'
  }, [dispatch])

  return (
    <Flex
      flexDirection="column"
      w="calc(100% + 3rem)"
      h="380px"
      m="0 -1.5rem"
    >
      <Flex
        position="relative"
        color="white"
        top="-1.3rem"
        backgroundColor="brand.green.100"
        w="100%"
        h="calc(40% + 25px)"
        zIndex={-1}
        borderRadius="24px 24px 0 0"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text fontSize="24px" align="center">Успешно!</Text>
      </Flex>
      <Flex
        w="100%"
        h="60%"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        p="30px 0"
      >
        <Text align="center">Мы сохранили Ваши результаты</Text>
        <Button onClick={handleClick}>Вернуться на главную</Button>
      </Flex>
    </Flex>
  )
}

export default memo(SaveResultSuccessPopup)
