export enum AppRoutes {
  MAIN = 'main',
  LOGIN = 'login',
  APARTMENTS = 'apartments',
  APARTMENT = 'apartment',
  EQUIPMENT = 'equipment',
  DIMENSION = 'dimension',
  RESULT = 'result',
  HISTORY = 'history',
  BEST = 'best',
  // last
  NOT_FOUND = 'not_found',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: '/',
  [AppRoutes.LOGIN]: '/login',
  [AppRoutes.APARTMENTS]: '/apartments',
  [AppRoutes.APARTMENT]: '/apartment/:id',
  [AppRoutes.EQUIPMENT]: '/equipment/:id',
  [AppRoutes.DIMENSION]: '/dimension/:id',
  [AppRoutes.RESULT]: '/result/:id',
  [AppRoutes.HISTORY]: '/history/:id',
  [AppRoutes.BEST]: '/best/:id',
  // last
  [AppRoutes.NOT_FOUND]: '*',
}

export const PageName: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: 'WiTuner',
  [AppRoutes.LOGIN]: 'Авторизация',
  [AppRoutes.APARTMENTS]: 'Планировка',
  [AppRoutes.APARTMENT]: 'Планировка',
  [AppRoutes.EQUIPMENT]: 'Оборудование',
  [AppRoutes.DIMENSION]: 'Измерения',
  [AppRoutes.RESULT]: 'Результат',
  [AppRoutes.HISTORY]: 'История',
  [AppRoutes.BEST]: 'Лучший результат',
  // last
  [AppRoutes.NOT_FOUND]: 'Не найдено',
}

export const ROUTES_WITH_STEPS = [
  AppRoutes.APARTMENT,
  AppRoutes.EQUIPMENT,
  AppRoutes.APARTMENTS,
  AppRoutes.DIMENSION,
  AppRoutes.RESULT,
  AppRoutes.HISTORY,
]

export const ROUTES_WITH_SORT = [AppRoutes.APARTMENTS]

export const ROUTES_WITH_DESCRIPTION = [
  AppRoutes.APARTMENT,
  AppRoutes.EQUIPMENT,
  AppRoutes.DIMENSION,
  AppRoutes.RESULT,
  AppRoutes.HISTORY,
  AppRoutes.BEST,
]

export const ROUTES_WITH_RELOAD = [
  AppRoutes.EQUIPMENT,
  AppRoutes.DIMENSION,
  AppRoutes.RESULT,
  AppRoutes.HISTORY,
]

export const ROADS = new Map([
  [
    AppRoutes.MAIN,
    {
      backPage: { requiredId: false, url: null },
      nextPage: { url: AppRoutes.APARTMENTS, requiredId: false },
    },
  ],
  [
    AppRoutes.APARTMENTS,
    {
      backPage: { requiredId: false, url: ' ' },
      nextPage: { requiredId: true, url: AppRoutes.APARTMENT },
    },
  ],
  [
    AppRoutes.APARTMENT,
    {
      backPage: { requiredId: false, url: AppRoutes.APARTMENTS },
      nextPage: { requiredId: true, url: AppRoutes.EQUIPMENT },
    },
  ],
  [
    AppRoutes.EQUIPMENT,
    {
      backPage: { requiredId: true, url: AppRoutes.APARTMENT },
      nextPage: { requiredId: true, url: AppRoutes.DIMENSION },
    },
  ],
  [
    AppRoutes.DIMENSION,
    {
      backPage: { requiredId: true, url: AppRoutes.EQUIPMENT },
      nextPage: { requiredId: true, url: AppRoutes.RESULT },
    },
  ],
  [
    AppRoutes.RESULT,
    {
      backPage: { requiredId: true, url: AppRoutes.EQUIPMENT },
      nextPage: { requiredId: true, url: AppRoutes.HISTORY },
    },
  ],
  [
    AppRoutes.HISTORY,
    {
      backPage: { requiredId: true, url: AppRoutes.EQUIPMENT },
      nextPage: { requiredId: true, url: AppRoutes.BEST },
    },
  ],
  [
    AppRoutes.BEST,
    {
      backPage: { requiredId: true, url: AppRoutes.APARTMENT },
      nextPage: { requiredId: false, url: null },
    },
  ],
])
