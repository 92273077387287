import Button from './button'
import Input from './input'
import Menu from './menu'
import Modal from './modal'
import Progress from './progress'
import Stepper from './stepper'

export const components = {
  Button,
  Input,
  Modal,
  Progress,
  Stepper,
  Menu,
}
