import { Image } from '@chakra-ui/react'
import { pageDescriptionActions } from 'features/pageDescription'
import React, { FC, memo, useEffect } from 'react'
import {
  DynamicModuleLoader,
  ReducersList,
} from 'shared/lib/components/DynamicModuleLoader'
import { getAbbreviatedText } from 'shared/lib/getAbbreviatedText/getAbbreviatedText'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Flex } from 'shared/ui/Flex'

import ApartmentDetailImageSkeleton from './Skeleton/ApartmentDetailImageSkeleton'

import {
  apartmentApi,
  useGetApartmentQuery,
} from '../../model/service/getApartment/getApartment'

const initialReducers: ReducersList = {
  [apartmentApi.reducerPath]: apartmentApi.reducer,
}

interface ApartmentDetailProps {
  id: string | null | undefined
}

const ApartmentDetail: FC<ApartmentDetailProps> = ({ id }) => {
  const { data, isLoading } = useGetApartmentQuery(id)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isLoading) {
      if (data?.data) {
        const description = getAbbreviatedText(data?.data.item.numberRooms, data?.data.item.squareMeters)
        dispatch(pageDescriptionActions.setDescription(description))
      }
      dispatch(pageDescriptionActions.setIsLoading(false))
    }
  }, [
    dispatch,
    data?.data,
    isLoading,
  ])

  return (
    <DynamicModuleLoader reducers={initialReducers}>
      <Flex
        w="100%"
        h="100%"
        p="24px 32px 24px 32px"
        flexDirection="column"
        justifyContent="center"
      >
        {isLoading
          ? (
              <ApartmentDetailImageSkeleton />
            )
          : (
              <Flex justifyContent="center">
                <Image
                  src={data?.data.item.url}
                  maxH="60vh"
                  h="60vh"
                  minH="60vh"
                />
              </Flex>
            )}
      </Flex>
    </DynamicModuleLoader>
  )
}

export default memo(ApartmentDetail)
