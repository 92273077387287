import { HStack } from '@chakra-ui/react'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Flex } from 'shared/ui/Flex'

import RouterSetupButton from './RouterSetupButton/RouterSetupButton'

import { getRouters } from '../../model/selectors/getRouters/getRouters'

const RouterSetupButtons = () => {
  const routers = useSelector(getRouters)

  return (
    <Flex
      align="center"
      h="50px"
      p="0 32px"
      maxH="50px"
      flexDirection="column"
    >
      <HStack w="100%" justify="space-between">
        {routers.map((router) => {
          return (
            <RouterSetupButton key={router.id} {...router} />
          )
        })}
      </HStack>
    </Flex>
  )
}

export default memo(RouterSetupButtons)
