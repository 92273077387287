import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

const gray400 = 'brand.gray.400'
const gray600 = 'brand.gray.600'
const secondary = 'secondary-color'
const blue500 = 'brand.blue.500'

const baseStyle = defineStyle({
  lineHeight: '1.1',
  fontWeight: '500',
  fontSize: '14px',
  px: '16px',
  borderRadius: '12px',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _focusVisible: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
})

const variantPrime = defineStyle((props) => {
  const { colorScheme: backgroundColor } = props

  return {
    backgroundColor,
    color: 'white',
    border: '2px solid',
    borderColor: backgroundColor,
    _hover: {
      borderColor: 'primary-hover',
      bg: 'primary-hover',
      _disabled: {
        borderColor: gray400,
        bg: gray400,
        color: gray600,
      },
    },
    _disabled: {
      opacity: 1,
      borderColor: gray400,
      bg: gray400,
      color: gray600,
    },
    _active: {
      borderColor: 'currentColor',
      backgroundColor,
    },
  }
})

const variantNoStyle = defineStyle({
  backgroundColor: 'unset',
  border: 'unset',
  padding: 'unset !important',
  paddingX: 'unset !important',
  outline: 'unset',
  borderRadius: 'unset',
  height: 'max-content !important',
  minWidth: 'max-content !important',
  alignItems: 'center',
  fontWeight: 'unset',
})

const variantSecond = defineStyle(() => {
  const backgroundColor = 'secondary'
  const color = secondary
  const hoverColor = 'secondary-hover'
  const borderColor = 'secondary-border'

  return {
    backgroundColor,
    color,
    border: '2px solid',
    borderColor: backgroundColor,
    _hover: {
      borderColor: hoverColor,
      bg: hoverColor,
    },
    _active: {
      borderColor: borderColor,
      backgroundColor,
    },
  }
})

const variantOutline = defineStyle(() => {
  const backgroundColor = 'transparent'
  const color = secondary
  const borderColor = 'outline-border'

  return {
    backgroundColor,
    color,
    border: 'solid 1px',
    borderColor: borderColor,
    _hover: {
      borderColor: color,
    },
    _active: {
      borderWidth: '2px',
      borderColor: borderColor,
      backgroundColor,
    },
    _disabled: {
      _hover: {
        borderColor: borderColor,
      },
      _active: {
        borderWidth: '1px',
      },
    },
  }
})

const variantGhost = defineStyle((props) => {
  const { color: c } = props
  const activeColor = 'brand.gray.300'

  return {
    backgroundColor: 'transparent',
    color: c,
    border: 'solid 2px',
    borderColor: 'transparent',
    _hover: {
      borderColor: activeColor,
    },
    _active: {
      borderColor: activeColor,
      backgroundColor: activeColor,
    },
  }
})

const variantThird = defineStyle(() => {
  const backgroundColor = '#fff'
  const color = secondary
  const hoverColor = 'secondary-hover'
  const borderColor = 'secondary-border'

  return {
    backgroundColor,
    color,
    border: '2px solid',
    borderColor: backgroundColor,
    _hover: {
      borderColor: hoverColor,
      bg: hoverColor,
    },
    _active: {
      borderColor: borderColor,
      backgroundColor,
    },
  }
})

const variantPagination = defineStyle(() => {
  const activeColor = gray400

  return {
    backgroundColor: 'transparent',
    color: 'brand.gray.900',
    border: 'solid 1px',
    borderColor: 'transparent',
    _hover: {
      borderColor: activeColor,
    },
    _active: {
      borderColor: activeColor,
      backgroundColor: activeColor,
    },
    _disabled: {
      cursor: 'default',
      opacity: 1,
      _hover: {
        borderColor: 'transparent',
      },
    },
  }
})

const variantMenuNav = defineStyle(() => {
  return {
    width: '100%',
    fontSize: '14px',
    py: '16px',
    paddingLeft: '8px',
    paddingRight: '8px',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    border: 'none',
    color: gray600,
    _hover: {
      backgroundColor: 'brand.gray.100',
    },
    _active: {
      color: 'brand.gray.900',
      backgroundColor: 'brand.gray.200',
    },
  }
})

const variantMenuMobileNav = defineStyle(() => {
  return {
    bg: '#ffffff',
    borderRadius: '12px',
    width: '100%',
    height: '48px',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
})

const variantSwipeable = defineStyle(() => {
  return {
    height: '32px',
    width: 'max-content',
    fontWeight: '400',
    fontSize: ['14px', null, '16px'],
    padding: '5px 16px',
    lineHeight: '110%',
    color: gray600,
    border: '1px solid',
    borderColor: gray400,
    borderRadius: '999px',
    flexShrink: 0,
    _selected: {
      borderColor: blue500,
      color: blue500,
      fontWeight: 500,
    },
    _active: {
      borderColor: blue500,
      color: blue500,
      fontWeight: 500,
    },
  }
})

const variantSwipeableTabs = defineStyle(() => {
  return {
    fontSize: ['16px', null, '28px'],
    height: ['19px', null, '34px'],
    color: 'brand.gray.500',
    lineHeight: '120%',
    padding: 0,
    _active: { color: 'black' },
  }
})

const variants = {
  noStyle: variantNoStyle,
  prime: variantPrime,
  secondary: variantSecond,
  third: variantThird,
  outline: variantOutline,
  ghost: variantGhost,
  pagination: variantPagination,
  menuNav: variantMenuNav,
  menuMobileNav: variantMenuMobileNav,
  swipeable: variantSwipeable,
  swipeableTabs: variantSwipeableTabs,
  icon: variantGhost,
  link: defineStyle({
    fontWeight: 400,
    fontSize: { base: '14px', sm: '16px' },
    lineHeight: '110%',
    color: gray600,
  }),
}

const sizes = {
  unset: defineStyle({}),
  lg: defineStyle((props) => {
    const { variant } = props

    return {
      h: '48px',
      minW: '48px',
      maxW: variant === 'icon' ? '48px' : 'unset',
      fontSize: '16px',
      px: variant === 'icon' ? 'unset' : '24px',
      borderRadius: '12px',
    }
  }),
  md: defineStyle((props) => {
    const { variant } = props

    return {
      h: '40px',
      minW: '40px',
      maxW: variant === 'icon' ? '40px' : 'unset',
      fontSize: '14px',
      px: variant === 'icon' ? 'unset' : '16px',
      borderRadius: '12px',
    }
  }),
  sm: defineStyle((props) => {
    const { variant } = props

    return {
      h: '32px',
      minW: '32px',
      maxW: variant === 'icon' ? '32px' : 'unset',
      fontSize: '12px',
      px: variant === 'icon' ? 'unset' : '16px',
      borderRadius: '12px',
    }
  }),
}

const Button = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'prime',
    size: 'lg',
    colorScheme: 'primary',
  },
})

export default Button
