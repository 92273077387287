import {
  Box as ChakraBox,
  BoxProps as ChakraBoxProps,
  forwardRef,
} from '@chakra-ui/react'
import { memo } from 'react'

export type Props = ChakraBoxProps

const Box = forwardRef<Props, 'div'>(function Box(props, ref) {
  return <ChakraBox ref={ref} {...props} />
})

export default memo(Box)
