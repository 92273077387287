import { enhancedStore } from 'app/providers/storeProvider'
import wifiImgSrc from 'assets/images/router_img.png'

import { DIMENSION } from '../../../store/DIMENSION'

const wifi_img = new Image()
wifi_img.crossOrigin = 'anonymous'
wifi_img.src = wifiImgSrc
wifi_img.width = 28
wifi_img.height = 28
const iconSize = 28

export const drawWifiIcon = () => {
  if (!DIMENSION.ctx) {
    return
  }
  const state = enhancedStore.getState()
  const routers = state.equipment?.routers || []
  routers.forEach(({ positions }) => {
    const [x, y] = positions
    if (!x || !y) return
    DIMENSION.ctx_hotmap?.drawImage(wifi_img, x, y, iconSize, iconSize)
    DIMENSION.ctx?.drawImage(wifi_img, x, y, iconSize, iconSize)
  })
}
