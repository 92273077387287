import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { OnboardingListRequest, OnboardingListResponse } from '../../types/onboardingTypes'

export const onboardingListApi = createApi({
  reducerPath: 'onboardingListApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getOnboardingList: builder.query<OnboardingListResponse, OnboardingListRequest>({
      query: () => '/onboarding',
    }),
  }),
})

export const { useGetOnboardingListQuery } = onboardingListApi
