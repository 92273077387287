import { stepperAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle }
  = createMultiStyleConfigHelpers(parts.keys)

const baseStyleStepper = defineStyle({})

const baseStyleStep = defineStyle({
  gap: 0,
})

const baseStyleTitle = defineStyle({})

const baseStyleDescription = defineStyle({})

const baseStyleIndicator = defineStyle({
  'width': '14px',
  'height': '14px',
  'borderColor': 'brand.gray.500',
  '&[data-status="active"]': {
    borderColor: 'brand.gray.500',
  },
  '&[data-status="complete"]': {
    backgroundColor: 'brand.green.500',
  },
})

const baseStyleSeparator = defineStyle({
  'm': '5px',
  'backgroundColor': 'brand.gray.500',
  '&[data-status="complete"]': {
    backgroundColor: 'brand.green.500',
  },
})

const baseStyleIcon = defineStyle({})

const baseStyleNumber = defineStyle({})

const baseStyle = definePartsStyle({
  stepper: baseStyleStepper,
  step: baseStyleStep,
  title: baseStyleTitle,
  description: baseStyleDescription,
  indicator: baseStyleIndicator,
  separator: baseStyleSeparator,
  icon: baseStyleIcon,
  number: baseStyleNumber,
})

const Stepper = defineMultiStyleConfig({
  baseStyle,
})

export default Stepper
