import { Skeleton } from '@chakra-ui/react'
import React, { CSSProperties, FC } from 'react'

interface ApartmentDetailImageSkeletonProps {
  styleImage?: CSSProperties
}

const ApartmentDetailImageSkeleton: FC<ApartmentDetailImageSkeletonProps> = ({ styleImage }) => {
  return (
    <Skeleton
      w="100%"
      h="100%"
      sx={styleImage}
    />
  )
}

export default ApartmentDetailImageSkeleton
