import { Link as ChakraLink, type LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import React, { FC, memo, MouseEvent, useCallback } from 'react'

import { useGetLinkQuery } from '../model/service/getLink/getLink'

interface LinkProps extends ChakraLinkProps {
  id: string
}

const Link: FC<LinkProps> = ({ id, ...props }) => {
  const { data } = useGetLinkQuery(id)
  const handleClickLink = useCallback((event: MouseEvent) => {
    event.stopPropagation()
    window.open(data?.data.item.urlValue, '_blank')
  }, [data?.data.item.urlValue])

  return (
    <ChakraLink {...props} onClick={handleClickLink}>{data?.data.item.urlName}</ChakraLink>
  )
}

export default memo(Link)
