import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { USER_LOCALSTORAGE_TOKEN_KEY } from 'shared/const/localstorage'

import { UserSchema } from '../types/user'

const initialState: UserSchema = {
  isAuth: !!localStorage.getItem(USER_LOCALSTORAGE_TOKEN_KEY),
  _inited: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload
    },
    initAuthData: (state) => {
      state._inited = true
    },
    logout: (state) => {
      localStorage.removeItem(USER_LOCALSTORAGE_TOKEN_KEY)
      state.isAuth = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: userActions } = userSlice
export const { reducer: userReducer } = userSlice
