import React, { memo, useCallback, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import { AppRoutes, RoutePath, ROUTES_WITH_SORT } from 'shared/const/route'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Menu, MenuButton, MenuItem, MenuList } from 'shared/ui/Menu'
import { Text } from 'shared/ui/Text'

import { getMenuItems } from '../../model/selectors/getMenuItems/getMenuItems'
import { getVisible } from '../../model/selectors/getVisible/getVisible'
import { sortMenuActions } from '../../model/slice/sortMenuSlice'

const SortMenu = () => {
  const location = useLocation()
  const isVisible = useSelector(getVisible)
  const menuItems = useSelector(getMenuItems)
  const dispath = useAppDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [hoverIndex, setHoverIndex] = useState<number | null>(null)
  const IconButton = menuItems[activeIndex]?.Icon

  const currentRoute = Object.keys(RoutePath).find((key) => {
    const path = RoutePath[key as AppRoutes]
    return matchPath(path, location.pathname)
  })

  useLayoutEffect(() => {
    dispath(sortMenuActions.setVisible(ROUTES_WITH_SORT.includes(currentRoute as AppRoutes)))
  }, [dispath, currentRoute])

  const handleClickSort = useCallback((index: number, callback: (() => void) | undefined) => {
    setActiveIndex(index)
    callback?.()
  }, [])

  if (!isVisible) {
    return null
  }
  return (
    <Menu>
      <MenuButton>
        {menuItems[activeIndex].text}
        {IconButton && <IconButton color="brand.blue.500" ml="5px" mb="2px" />}
      </MenuButton>
      <MenuList>
        {menuItems.map(({ text, Icon, onClick }, index) => (
          <MenuItem
            key={index}
            onClick={() => handleClickSort(index, onClick)}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(null)}
            justifyContent="space-between"
            color={activeIndex === index || hoverIndex === index ? 'brand.blue.500' : 'black'}
          >
            <Text>{text}</Text>
            {Icon && <Icon color={activeIndex === index || hoverIndex === index ? 'brand.blue.500' : 'black'} />}
          </MenuItem>
        ))}

      </MenuList>
    </Menu>
  )
}

export default memo(SortMenu)
