import { displayHotmap } from './displayHotmap'
import { drawWifiIcon } from './drawWifiIcon'
import { mouseUpEvent } from './mouseUpEvent'
import { updateWaves } from './updateWaves'

import { DIMENSION } from '../../../store/DIMENSION'

// ================== ФУНКЦИЯ ОТРИСОВКИ (draw) ==================
export const draw = () => {
  switch (DIMENSION.stage) {
    case 'prepare':
      DIMENSION.canvasElement?.addEventListener('click', mouseUpEvent)
      break
    case 'dimension':
      if (DIMENSION.imgData && DIMENSION.ctx) {
        updateWaves()
        DIMENSION.ctx.putImageData(DIMENSION.imgData, 0, 0)
      }
      break
    case 'results':
      if (DIMENSION.imgData_hotmap && DIMENSION.ctx_hotmap) {
        displayHotmap()
        DIMENSION.ctx_hotmap.putImageData(DIMENSION.imgData_hotmap, 0, 0)
      }
      break
  }
  // перерисовка иконки wifi
  drawWifiIcon()

  DIMENSION.Counter++
  if (!DIMENSION.stop) {
    requestAnimationFrame(draw)
  }
}
