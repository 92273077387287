import { popupActions } from 'entities/Popup'
import React, { FC, memo, useCallback } from 'react'
import { PopupTypes } from 'shared/const/PopupTypes'
import { InfoIcon } from 'shared/icons/InfoIcon'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Button, ButtonProps } from 'shared/ui/Button'

const OpenOnboardingButton: FC<ButtonProps> = (props) => {
  const dispatch = useAppDispatch()
  const handleClickOpenOnboarding = useCallback(() => {
    dispatch(
      popupActions.open({
        params: {
          type: PopupTypes.onboardingPopup,
          props: {},
        },
        variant: 'fullscreen',
        canClose: false,
        visibleCloseButton: true,
      }),
    )
  }, [dispatch])

  return (
    <Button
      variant="link"
      onClick={handleClickOpenOnboarding}
      {...props}
    >
      <InfoIcon />
    </Button>
  )
}

export default memo(OpenOnboardingButton)
