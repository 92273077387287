import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  DynamicModuleLoader,
  ReducersList,
} from 'shared/lib/components/DynamicModuleLoader'
import { Flex } from 'shared/ui/Flex'
import { Progress } from 'shared/ui/Progress'

import { getDimensionProgress } from '../../model/selectors/getDimensionProgress/getDimensionProgress'
import { dimensionReducer } from '../../model/slice/dimensionSlice'

const initialReducers: ReducersList = {
  dimension: dimensionReducer,
}

const DimensionProgress = () => {
  const progress = useSelector(getDimensionProgress)
  return (
    <DynamicModuleLoader reducers={initialReducers}>
      <Flex
        align="center"
        h="50px"
        p="0 32px"
        maxH="50px"
      >
        <Progress value={progress} />
      </Flex>
    </DynamicModuleLoader>
  )
}

export default memo(DimensionProgress)
