import { Skeleton } from '@chakra-ui/react'
import React, { FC, memo, useMemo } from 'react'
import { DynamicModuleLoader, ReducersList } from 'shared/lib/components/DynamicModuleLoader'

import RoomCard from './RoomCard/RoomCard'

import { roomsApi, useGetRoomsQuery } from '../../model/service/getRooms/getRooms'

const initialReducers: ReducersList = {
  [roomsApi.reducerPath]: roomsApi.reducer,
}

interface RoomsListProps {
  handleClickNext: (room: number) => void
}

const RoomsList: FC<RoomsListProps> = ({ handleClickNext }) => {
  const { data, isLoading, isSuccess } = useGetRoomsQuery({})

  const content = useMemo(() => {
    switch (true) {
      case isLoading:
        return (
          <React.Fragment>
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton key={index} w="100%" h="65px" />
            ))}
          </React.Fragment>
        )
      case isSuccess:
        return (
          <React.Fragment>
            {data?.data.list?.map(({ numberRooms }, index) => (
              <RoomCard key={`${index}_${numberRooms}`} room={numberRooms} onClick={handleClickNext}>
                {`${numberRooms} - комнатная`}
              </RoomCard>
            ))}
          </React.Fragment>
        )
    }
  }, [handleClickNext, isLoading, isSuccess, data?.data.list])

  return (
    <DynamicModuleLoader reducers={initialReducers}>
      {content}
    </DynamicModuleLoader>
  )
}

export default memo(RoomsList)
