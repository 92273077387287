import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { SaveRequest, SaveResponse } from '../../types/save'

export const saveResultApi = createApi({
  reducerPath: 'saveResultApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    save: builder.mutation<SaveResponse, SaveRequest>({
      query: body => ({
        url: '/coverage/save',
        method: 'POST',
        body: body,
      }),
    }),
  }),
})

export const { useSaveMutation } = saveResultApi
