import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { userActions } from 'entities/User'

import { BASE_URL } from '../../const/api'
import { USER_LOCALSTORAGE_TOKEN_KEY } from '../../const/localstorage'

interface RefreshTokenResponse {
  accessToken: string
}

export type FetchError = FetchBaseQueryError & {
  data: {
    errors?: string[]
    message?: string
    status?: number
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem(USER_LOCALSTORAGE_TOKEN_KEY)
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)

    if (refreshResult.data) {
      const { accessToken } = refreshResult.data as RefreshTokenResponse
      localStorage.setItem(USER_LOCALSTORAGE_TOKEN_KEY, accessToken)
      result = await baseQuery(args, api, extraOptions)
    }
    else {
      api.dispatch(userActions.logout())
    }
  }

  return result
}
