import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ScrollPositionShema } from '../types/scrollPositionSchema'

const initialState: ScrollPositionShema = {
  position: {},
}

export const scrollPositionSlice = createSlice({
  name: 'scrollPosition',
  initialState,
  reducers: {
    setScrollPosition(state, action: PayloadAction<Record<string, number>>) {
      state.position = {
        ...state.position,
        ...action.payload,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: scrollPositionActions } = scrollPositionSlice
export const { reducer: scrollPositionReducer } = scrollPositionSlice
