export enum Breakpoint {
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
  'xl' = 'xl',
  '2xl' = '2x',
  '3xl' = '3x',
}

const breakpoints: Record<Breakpoint, string> = {
  [Breakpoint.xs]: '22.5em', // 360px
  [Breakpoint.sm]: '30em', // 480px
  [Breakpoint.md]: '48em', // 768px
  [Breakpoint.lg]: '62em', // 992px
  [Breakpoint.xl]: '80em', // 1280px
  [Breakpoint['2xl']]: '96em', // 1536px
  [Breakpoint['3xl']]: '120em', // 1920px
}

export const breakpointsPx: Record<Breakpoint, string> = {
  [Breakpoint.xs]: '360px',
  [Breakpoint.sm]: '480px',
  [Breakpoint.md]: '768px',
  [Breakpoint.lg]: '992px',
  [Breakpoint.xl]: '1280px',
  [Breakpoint['2xl']]: '1536px',
  [Breakpoint['3xl']]: '1920px',
}

export default breakpoints
