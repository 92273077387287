import React, { memo, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import { AppRoutes, RoutePath, ROUTES_WITH_STEPS } from 'shared/const/route'
import { CompleteIcon } from 'shared/icons/CompleteIcon'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import {
  Step,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
} from 'shared/ui/Stepper'

import { getActiveStep } from '../model/selectors/getActiveStep/getActiveStep'
import { getSteps } from '../model/selectors/getSteps/getSteps'
import { getVisible } from '../model/selectors/getVisible/getVisible'
import { dimensionStepsActions } from '../model/slice/dimensionStepsSlice'

const DimensionSteps = () => {
  const location = useLocation()
  const isVisible = useSelector(getVisible)
  const activeStep = useSelector(getActiveStep)
  const steps = useSelector(getSteps)
  const dispath = useAppDispatch()

  const currentRoute = Object.keys(RoutePath).find((key) => {
    const path = RoutePath[key as AppRoutes]
    return matchPath(path, location.pathname)
  })

  useLayoutEffect(() => {
    dispath(dimensionStepsActions.setVisible(ROUTES_WITH_STEPS.includes(currentRoute as AppRoutes)))
  }, [dispath, currentRoute])

  if (!isVisible) {
    return null
  }

  return (
    <Stepper index={activeStep}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus complete={<CompleteIcon />} />
          </StepIndicator>
          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  )
}

export default memo(DimensionSteps)
