import React, { FC, memo, useCallback } from 'react'
import { RouterIcon } from 'shared/icons/RouterIcon'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Button } from 'shared/ui/Button'

import { equipmentActions } from '../../../model/slice/equipmentSlice'
import { Routers } from '../../../model/types/EquipmentSchema'

const RouterSetupButton: FC<Routers> = ({ id, state }) => {
  const dispatch = useAppDispatch()
  const disabled = state === 0
  const selected = state === 2
  const active = state === 3

  const onClickRouter = useCallback(() => {
    state === 1 && dispatch(equipmentActions.setActiveRouter({ id, state: 2, positions: [] }))
  }, [dispatch, id, state])

  return (
    <Button
      border="2px solid"
      borderColor={selected || active ? 'brand.orange.500' : 'brand.gray.500'}
      backgroundColor="transparent !important"
      opacity={disabled ? '0.5 !important' : '1 !important'}
      cursor="pointer"
      isDisabled={disabled}
      borderRadius="6px"
      p="4px"
      key={id}
      onClick={onClickRouter}
    >
      <RouterIcon />
    </Button>
  )
}

export default memo(RouterSetupButton)
