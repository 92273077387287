import {
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps as ChakraFormErrorMessageProps,
  forwardRef,
} from '@chakra-ui/react'

export type Props = ChakraFormErrorMessageProps

const FormErrorMessage = forwardRef<Props, 'div'>(function FormErrorMessage(
  props,
  ref,
) {
  return <ChakraFormErrorMessage ref={ref} {...props} />
})

export default FormErrorMessage
