const styles = {
  global: {
    body: {
      bg: 'white',
      minHeight: '100%',
      height: '100%',
      maxHeight: '100%',
    },
    html: {
      // overflow: 'hidden',
    },
  },
}

export default styles
