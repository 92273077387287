import { FC, KeyboardEvent, memo, ReactNode, SetStateAction, useState } from 'react'

import { Flex } from '../../Flex'

interface ItemProps {
  setTrackIsActive: (state: SetStateAction<boolean>) => void
  trackIsActive: boolean
  setActiveItem: (state: SetStateAction<number>) => void
  activeItem: number
  itemWidth: number
  positions: number[]
  index: number
  children: ReactNode
}

const Item: FC<ItemProps> = ({
  setTrackIsActive,
  setActiveItem,
  activeItem,
  itemWidth,
  positions,
  children,
  index,
}) => {
  const [userDidTab, setUserDidTab] = useState(false)

  const handleFocus = () => setTrackIsActive(true)

  const handleBlur = () => {
    userDidTab && index + 1 === positions.length && setTrackIsActive(false)
    setUserDidTab(false)
  }

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) =>
    event.key === 'Tab'
    && !(activeItem === positions.length)
    && setActiveItem(index)

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) =>
    event.key === 'Tab' && setUserDidTab(true)

  return (
    <Flex
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      w={`${itemWidth}px`}
      h="100%"
      py="4px"
    >
      {children}
    </Flex>
  )
}

export default memo(Item)
