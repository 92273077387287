import {
  CloseButton as ChakraCloseButton,
  CloseButtonProps as ChakraCloseButtonProps,
} from '@chakra-ui/close-button'
import { forwardRef, useMultiStyleConfig } from '@chakra-ui/react'
import { memo } from 'react'

import { CloseIcon } from '../../icons/CloseIcon'

export type CloseButtonProps = ChakraCloseButtonProps

export const CloseButton = forwardRef<CloseButtonProps, 'button'>(
  function CloseButton({ __css, ...props }, ref) {
    const { button, icon } = useMultiStyleConfig('CloseButton', props)

    return (
      <ChakraCloseButton ref={ref} {...props} __css={{ ...button, ...__css }}>
        <CloseIcon strokeWidth="1.5" __css={icon} />
      </ChakraCloseButton>
    )
  },
)

export default memo(CloseButton)
