import { getUserIsAuth } from 'entities/User'
import { Toast } from 'features/toast'
import React, { memo, Suspense, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { AppRoutes } from 'shared/const/route'
import { Loader } from 'shared/ui/Loader'
import { Navbar } from 'widgets/Navbar'

import { RequireAuth } from './RequireAuth'

import { AppRoutesProps, routeConfig } from '../config/routeConfig'

const AppRouter = () => {
  const isAuth = useSelector(getUserIsAuth)

  const routes = useMemo(() => {
    if (isAuth) {
      const { [AppRoutes.LOGIN]: _, ...newRoutes } = routeConfig
      return newRoutes
    }
    else {
      return routeConfig
    }
  }, [isAuth])

  const renderWithWrapper = useCallback((route: AppRoutesProps) => {
    const element = (
      <Suspense fallback={<Loader withWrapper />}>
        <React.Fragment>
          <Toast />
          {isAuth && <Navbar />}
          {route.element}
        </React.Fragment>
      </Suspense>
    )
    return (
      <Route
        key={route.path}
        path={route.path}
        element={route.authOnly ? <RequireAuth>{element}</RequireAuth> : element}
      />
    )
  }, [isAuth])
  return (
    <Routes>
      {Object.values(routes).map(renderWithWrapper)}
    </Routes>
  )
}

export default memo(AppRouter)
