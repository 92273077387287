import React, { ComponentType, useCallback, useEffect } from 'react'

const withZoomDisabled = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  const HOC: React.FC<P> = (props) => {
    const mousewheelListener = useCallback((event: WheelEvent) => {
      if (!event.ctrlKey && !event.metaKey) return

      event.preventDefault()
      event.stopImmediatePropagation()
    }, [])

    const gesturestartListener = useCallback((event: Event) => {
      event.preventDefault()
      event.stopImmediatePropagation()
    }, [])

    const keydownListener = useCallback((event: KeyboardEvent) => {
      if (!event.ctrlKey && !event.metaKey) return
      if (event.keyCode != 189 && event.keyCode != 187) return

      event.preventDefault()
      event.stopImmediatePropagation()
    }, [])

    useEffect(() => {
      // отключение zoom через скролл (в том числе трекападами в macOS)
      document.addEventListener('wheel', mousewheelListener, {
        passive: false,
      })

      // отключение zoom прикосновениями (в том числе трекападами и т.п.) в Safari и iOS
      document.addEventListener('gesturestart', gesturestartListener, {
        passive: false,
      })

      // отключение zoom через клавиатуру (ctrl + "+", ctrl + "-")
      // кнопки браузера для управления zoom отключены не будут
      document.addEventListener('keydown', keydownListener, { passive: false })

      return () => {
        document.removeEventListener('wheel', mousewheelListener)
        document.removeEventListener('gesturestart', gesturestartListener)
        document.removeEventListener('keydown', keydownListener)
      }
    }, [mousewheelListener, gesturestartListener, keydownListener])
    return <WrappedComponent {...props} />
  }

  // Присвоим дисплейное имя
  const wrappedComponentName
    = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  HOC.displayName = `withZoomDisabled(${wrappedComponentName})`

  return HOC
}

export default withZoomDisabled
