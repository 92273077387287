import React from 'react'
import { Flex } from 'shared/ui/Flex'

import LoginForm from '../LoginForm/LoginForm'

const Login = () => {
  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <LoginForm />
    </Flex>
  )
}

export default Login
