import { forwardRef } from '@chakra-ui/react'
import { memo } from 'react'

import { Icon, IconProps } from '../../ui/Icon'

export type Props = IconProps
const LogoIcon = forwardRef<Props, 'svg'>(function LogoIcon(
  { width = '266px', height = '166px', color = 'primary', ...props },
  ref,
) {
  return (
    <Icon
      viewBox="0 0 266 166"
      w={width}
      height={height}
      color={color}
      fill="none"
      ref={ref}
      {...props}
    >
      <path d="M2.47212 2.38562C0.988848 3.87663 0 5.96407 0 7.65388C0 9.1449 10.6796 44.4323 23.7323 85.982C43.6082 149.4 47.8602 161.925 49.9368 163.714C52.5078 166 53.0022 166 78.4156 166C99.9725 166 104.719 165.702 106.499 164.509C108.872 162.819 110.553 158.744 123.705 121.468C128.847 107.055 133.099 95.7234 133.297 96.3198C133.494 96.8168 138.735 111.727 144.866 129.42C153.667 154.469 156.732 162.024 158.71 163.813C161.281 166 161.775 166 187.189 166C215.47 166 216.162 165.901 218.338 160.334C220.612 154.768 266 8.84669 266 7.35568C266 6.46107 264.715 4.47304 263.132 2.88261L260.265 -1.52588e-05H233.368C202.714 -1.52588e-05 201.033 0.298187 198.561 6.56047C197.671 8.7473 193.122 23.8563 188.375 40.2575C183.629 56.6587 179.476 70.376 179.08 70.7736C178.685 71.1712 173.741 56.4599 168.005 37.9713C155.051 -3.97606 158.611 -0.198807 131.715 0.198792L112.531 0.496994L109.96 3.47903C107.883 5.76526 105.016 14.0155 97.5004 38.2694C92.1606 55.7641 87.4141 70.376 87.1175 70.7736C86.7219 71.2707 82.0743 56.6587 76.8335 38.5677C69.2193 12.3257 66.7472 4.97005 64.7695 2.98203L62.2974 0.496994L33.6208 0.198792L4.94424 -0.099411L2.47212 2.38562Z" fill="#FE5000" />
    </Icon>
  )
})

export default memo(LogoIcon)
