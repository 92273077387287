import { Skeleton } from '@chakra-ui/react'
import React from 'react'

import { Flex } from '../../../Flex'

const SkeletonImage = () => {
  return (
    <Flex
      h="100%"
      p={5}
    >
      <Skeleton
        h="100%"
        w="100%"
      />
    </Flex>
  )
}

export default SkeletonImage
