import {
  getCountRouters,
  getHotmapAverage,
  getHotmapImage,
  getHotmapStrong,
  getHotmapWeak,
} from 'entities/Dimension'
import { popupActions } from 'entities/Popup'
import { toastActions } from 'features/toast'
import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { PopupTypes } from 'shared/const/PopupTypes'
import { FetchError } from 'shared/lib/baseQueryWithReauth/baseQueryWithReauth'
import {
  DynamicModuleLoader,
  ReducersList,
} from 'shared/lib/components/DynamicModuleLoader'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Button } from 'shared/ui/Button'

import { saveResultApi, useSaveMutation } from '../../model/services/save/save'

interface SaveButtonProps {
  id: string | null | undefined
}

const initialReducers: ReducersList = {
  [saveResultApi.reducerPath]: saveResultApi.reducer,
}

const SaveButton: FC<SaveButtonProps> = ({ id }) => {
  const dispatch = useAppDispatch()

  const strong = useSelector(getHotmapStrong)
  const average = useSelector(getHotmapAverage)
  const weak = useSelector(getHotmapWeak)
  const countRouters = useSelector(getCountRouters)

  const image = useSelector(getHotmapImage)

  const [save] = useSaveMutation()

  const handleClickSave = useCallback(async () => {
    const payload = {
      image_base64: image || '',
      router_count: countRouters,
      best_score: strong,
      average_score: average,
      worst_score: weak,
      flat_id: Number(id),
    }
    try {
      const { data, error } = await save(payload)
      if (data) {
        dispatch(
          popupActions.open({
            params: {
              type: PopupTypes.saveResultSuccess,
              props: {},
            },
          }),
        )
      }
      else {
        dispatch(
          toastActions.setToast({
            text: (error as FetchError).data?.message || 'Что-то пошло не так',
            status: 'error',
            delay: 5000,
          }),
        )
      }
    }
    catch (e) {
      dispatch(
        toastActions.setToast({
          text: 'Что-то пошло не так',
          status: 'error',
          delay: 5000,
        }),
      )
    }
  }, [save, image, strong, average, weak, countRouters, id, dispatch])

  return (
    <DynamicModuleLoader reducers={initialReducers}>
      <Button w="100%" mt="10px" size="md" onClick={handleClickSave}>
        Сохранить результаты
      </Button>
    </DynamicModuleLoader>
  )
}

export default SaveButton
