import {
  forwardRef,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { memo, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { FormLabel } from '../FormControl'

type HTMLInputProps = Omit<ChakraInputProps, 'value' | 'onChange' | 'readOnly'>

const MotionLabel = motion(FormLabel)

interface InputProps extends HTMLInputProps {
  className?: string
  value?: string | number
  onChange?: (value: string) => void
  autofocus?: boolean
  readonly?: boolean
}

const Input = forwardRef<InputProps, 'input'>(function Input({ autofocus = false, onChange, value, placeholder, ...props }, ref) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(!!value)

  useImperativeHandle(ref, () => inputRef.current!)
  useEffect(() => {
    if (autofocus) {
      inputRef.current?.focus()
    }
  }, [autofocus])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFilled(!!e.target.value)
    onChange?.(e.target.value)
  }
  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  return (
    <React.Fragment>
      <MotionLabel
        position="absolute"
        left="20px"
        zIndex={isFocused || isFilled ? 100 : 0}
        pointerEvents="none"
        color={isFocused ? 'brand.orange.500' : 'brand.gray.500'}
        transform="translateY(-50%)"
        initial={{ top: '50%' }}
        animate={{
          top: isFocused || isFilled ? '14px' : '50%',
          fontSize: isFocused || isFilled ? '10px' : '14px',
        }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
      >
        {placeholder}
      </MotionLabel>
      <ChakraInput
        ref={inputRef}
        {...props}
        onFocus={handleFocus}
        placeholder={isFocused || isFilled ? '' : placeholder}
        paddingTop={isFocused || isFilled ? '15px' : '10px'}
        paddingBottom={isFocused || isFilled ? '5px' : '10px'}
        onBlur={handleBlur}
        value={value}
        onChange={onChangeHandler}
      />
    </React.Fragment>
  )
})
export default memo(Input)
