import React, { FC, memo, useMemo } from 'react'
import {
  DynamicModuleLoader,
  ReducersList,
} from 'shared/lib/components/DynamicModuleLoader'
import { Box } from 'shared/ui/Box'
import { Flex } from 'shared/ui/Flex'
import { HStack } from 'shared/ui/Stack'
import { Text } from 'shared/ui/Text'

import {
  bestResultApi,
  useGetBestResultQuery,
} from '../../model/service/getBestResult/getBestResult'

interface HotmapBestResultProps {
  visibleText?: boolean
  id: string | null | undefined
  routerCount: number
}

const initialReducers: ReducersList = {
  [bestResultApi.reducerPath]: bestResultApi.reducer,
}

const HotmapBestResult: FC<HotmapBestResultProps> = ({
  visibleText = true,
  id,
  routerCount,
}) => {
  const { data, isLoading } = useGetBestResultQuery({
    id,
    params: { routerCount: `${routerCount}` },
  })

  const content = useMemo(() => {
    const strong = data?.data.item.bestScore || 0
    const average = data?.data.item.averageScore || 0
    const weak = data?.data.item.worstScore || 0

    switch (true) {
      case isLoading:
        return <Text>Загрузка...</Text>
      case !data && !isLoading:
        return <Text>Результатов нет...</Text>
      case !!data && !isLoading:
        return (
          <React.Fragment>
            <HStack w="100%">
              {strong && (
                <Box
                  w={`${strong}%`}
                  h="12px"
                  borderRadius="64px"
                  backgroundColor="brand.green.200"
                  position="relative"
                >
                  {strong > 15 && (
                    <Text
                      fontSize="15px"
                      w="100%"
                      align="center"
                      fontWeight="500"
                      position="absolute"
                      bottom="15px"
                    >
                      {strong}
                      %
                    </Text>
                  )}
                </Box>
              )}
              {average && (
                <Box
                  w={`${average}%`}
                  h="12px"
                  borderRadius="64px"
                  backgroundColor="brand.yellow"
                  position="relative"
                >
                  {average > 15 && (
                    <Text
                      fontSize="15px"
                      w="100%"
                      align="center"
                      fontWeight="500"
                      position="absolute"
                      bottom="15px"
                    >
                      {average}
                      %
                    </Text>
                  )}
                </Box>
              )}
              {weak && (
                <Box
                  w={`${weak}%`}
                  h="12px"
                  borderRadius="64px"
                  backgroundColor="brand.gray.500"
                  position="relative"
                >
                  {weak > 15 && (
                    <Text
                      fontSize="15px"
                      w="100%"
                      align="center"
                      fontWeight="500"
                      position="absolute"
                      bottom="15px"
                    >
                      {weak}
                      %
                    </Text>
                  )}
                </Box>
              )}
            </HStack>
            {visibleText && (
              <Flex
                w="100%"
                justifyContent="space-between"
                color="brand.gray.600"
              >
                <Text fontSize="11px" fontWeight="700">
                  — 35 DBM
                </Text>
                <Text fontSize="11px" fontWeight="700">
                  — 95 DBM
                </Text>
              </Flex>
            )}
          </React.Fragment>
        )
      default:
        return null
    }
  }, [data, isLoading, visibleText])

  return (
    <DynamicModuleLoader reducers={initialReducers}>
      <Flex
        align={visibleText ? 'center' : 'start'}
        flexDirection="column"
        mt="30px"
        h="50px"
        maxH="50px"
        justifyContent="center"
      >
        {content}
      </Flex>
    </DynamicModuleLoader>
  )
}

export default memo(HotmapBestResult)
