import { RefCallback, useCallback, useLayoutEffect, useState } from 'react'

type Dimensions = {
  width: number
  height: number
  top: number
  left: number
  x: number
  y: number
  right: number
  bottom: number
}

const debounce = (limit: number, callback: () => void) => {
  let timeoutId: number
  return (...args: Parameters<typeof callback>) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = window.setTimeout(() => callback(...args), limit)
  }
}

function getDimensionObject(node: HTMLDivElement): Dimensions {
  const rect = node.getBoundingClientRect()
  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    x: rect.x,
    y: rect.y,
    right: rect.right,
    bottom: rect.bottom,
  }
}

export const useBoundingRect = (
  limit?: number,
): [
  RefCallback<HTMLDivElement>,
  Dimensions | Record<string, never>,
  HTMLDivElement | null,
] => {
  const [dimensions, setDimensions] = useState({})
  const [node, setNode] = useState<HTMLDivElement | null>(null)

  const ref = useCallback((node: HTMLDivElement) => {
    setTimeout(() => {
      // ждем пока нода зарендериться
      setNode(node)
    }, 200)
  }, [])

  useLayoutEffect(() => {
    if ('undefined' !== typeof window && node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node)),
        )

      measure()

      const listener = debounce(limit || 100, measure)

      window.addEventListener('resize', listener)
      window.addEventListener('scroll', listener)
      return () => {
        window.removeEventListener('resize', listener)
        window.removeEventListener('scroll', listener)
      }
    }
  }, [node, limit])

  return [ref, dimensions, node]
}
