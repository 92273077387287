import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IMenuItems, SortMenuSchema } from '../types/SortMenu'

const initialState: SortMenuSchema = {
  visible: false,
  menuItems: [],
}

export const sortMenuSlice = createSlice({
  name: 'sortMenu',
  initialState,
  reducers: {
    setVisible: (state, action) => {
      state.visible = action.payload
    },
    setMenuItems: (state, action: PayloadAction<IMenuItems[]>) => {
      state.menuItems = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: sortMenuActions } = sortMenuSlice
export const { reducer: sortMenuReducer } = sortMenuSlice
