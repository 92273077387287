import {
  forwardRef,
  Stack as ChakraStack,
  StackProps as ChakraStackProps,
} from '@chakra-ui/react'

export type Props = ChakraStackProps

export const Stack = forwardRef<Props, 'div'>(function Stack(props, ref) {
  return <ChakraStack ref={ref} {...props} />
})

export const HStack = forwardRef<Props, 'div'>(function HStack(props, ref) {
  return <Stack align="center" {...props} direction="row" ref={ref} />
})

export const VStack = forwardRef<Props, 'div'>(function VStack(props, ref) {
  return <Stack align="center" {...props} direction="column" ref={ref} />
})

export default Stack
