import React, { FC, SetStateAction } from 'react'

import { Box } from '../../Box'
import { HStack } from '../../Stack'

interface CarouselIndicatorProps {
  activeItem: number
  count: number
  setActiveItem: (state: SetStateAction<number>) => void
}

const CarouselIndicator: FC<CarouselIndicatorProps> = ({ activeItem, count, setActiveItem }) => {
  return (
    <HStack gap="5px" w="100%">
      {Array.from({ length: count }, (_, index) => index).map((_, index) => (
        <Box
          key={index}
          w="100%"
          h="2px"
          borderRadius="1px"
          bg={activeItem >= index ? 'brand.orange.500' : 'brand.gray.500'}
          mx="2px"
          cursor="pointer"
          onClick={() => setActiveItem(index)}
        />
      ))}
    </HStack>
  )
}

export default CarouselIndicator
