import { useMediaQuery } from '@chakra-ui/react'

import { Breakpoint, breakpointsPx } from '../../../const/breakpoints'

export const useIsBreakpoint = (bp: Breakpoint): boolean => {
  const [isBreakpoint] = useMediaQuery(`(min-width: ${breakpointsPx[bp]})`, {
    ssr: false,
    fallback: false,
  })

  return isBreakpoint
}
