import { ChakraProvider } from '@chakra-ui/react'
import reportWebVitals from 'app/analysis/reportWebVitals'
import App from 'app/App'
import { enhancedStore } from 'app/providers/storeProvider'
import theme from 'app/styles/theme'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
  <BrowserRouter>
    <Provider store={enhancedStore}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
