import {
  forwardRef,
  StepIndicator as ChakraStepIndicator,
  StepIndicatorProps as ChakraStepIndicatorProps,
} from '@chakra-ui/react'
import { FC } from 'react'

export type BaseStepIndicatorProps = ChakraStepIndicatorProps
export const BaseStepIndicator = forwardRef<BaseStepIndicatorProps, 'div'>(
  function BaseStepIndicator(props, _) {
    return <ChakraStepIndicator {...props} />
  },
)

export type StepIndicatorProps = BaseStepIndicatorProps

export const StepIndicator: FC<StepIndicatorProps> = (props) => {
  return (
    <BaseStepIndicator
      {...props}
    />
  )
}
