import { createSlice } from '@reduxjs/toolkit'

const initialState: Record<string, URLSearchParams> = {}

export const lastSearchParamsSlice = createSlice({
  name: 'lastSearchParams',
  initialState,
  reducers: {
    setLastSearchParams: (state, action) => {
      const { page, searchParams } = action.payload
      state[page] = searchParams
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: lastSearchParamsSliceActions } = lastSearchParamsSlice
export const { reducer: lastSearchParamsSliceReducer } = lastSearchParamsSlice
