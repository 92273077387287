import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  forwardRef,
} from '@chakra-ui/react'
import { memo } from 'react'

export type Props = ChakraButtonProps

const Button = forwardRef<Props, 'button'>(function Button(props, ref) {
  return <ChakraButton ref={ref} {...props} />
})
export default memo(Button)
