import { forwardRef } from '@chakra-ui/react'
import { memo } from 'react'

import { Icon, IconProps } from '../../ui/Icon'

export type Props = IconProps
const ExitIcon = forwardRef<Props, 'svg'>(function ExitIcon(
  { width = '18px', height = '20px', color = 'primary', ...props },
  ref,
) {
  return (
    <Icon
      viewBox="0 0 18 20"
      w={width}
      h={height}
      color={color}
      fill="none"
      ref={ref}
      {...props}
    >
      <path d="M0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H10V2H2V18H10V20H1C0.447715 20 0 19.5523 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893Z" fill="#FE5000" />
      <path d="M14.5858 11H6V9H14.5858L12.2929 6.70706L13.7071 5.29285L17.7071 9.29285C18.0976 9.68337 18.0976 10.3165 17.7071 10.7071L13.7071 14.7071L12.2929 13.2928L14.5858 11Z" fill="#FE5000" />
    </Icon>
  )
})

export default memo(ExitIcon)
