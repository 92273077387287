import { FC } from 'react'

import { LoaderIcon } from '../../icons/LoaderIcon'
import { Flex } from '../Flex'

interface LoaderProps {
  withWrapper?: boolean
}

const Loader: FC<LoaderProps> = ({ withWrapper = false }) => {
  const body = (
    <LoaderIcon />
  )
  return withWrapper
    ? (
        <Flex
          sx={{
            height: '100vh',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {body}
        </Flex>
      )
    : (
        body
      )
}

export default Loader
