import { extendTheme } from '@chakra-ui/react'
import breakpoints from 'shared/const/breakpoints'

import { components } from './components'
import { fonts } from './font'
// Foundational style overrides
import { foundations } from './foundations'
import { semanticTokens } from './semantic-tokens'
// Global style overrides
import styles from './styles'

const overrides = {
  styles,
  fonts,
  components,
  // Other foundational style overrides go here
  ...foundations,
  breakpoints,
  semanticTokens,
}
export default extendTheme(overrides)
