import { forwardRef } from '@chakra-ui/react'
import { memo } from 'react'

import { Icon, IconProps } from '../../ui/Icon'

export type Props = IconProps
const CloseIcon = forwardRef<Props, 'svg'>(function CloseIcon(
  { strokeWidth = '1.25', ...props },
  ref,
) {
  return (
    <Icon viewBox="0 0 12 12" ref={ref} {...props}>
      <path
        d="M11.3676 11.3677L0.632324 0.632362M11.3676 0.632362L0.632324 11.3677"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
})

export default memo(CloseIcon)
