import { StateSchema } from 'app/providers/storeProvider'
import { useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getScrollPosition } from '../selectors/getScrollPosition/getScrollPosition'
import { scrollPositionActions } from '../slice/scrollPositionSlice'

interface ScrollParams {
  page: string
}

export const useScrollPosition = ({ page }: ScrollParams) => {
  const dispatch = useDispatch()
  const position = useSelector((state: StateSchema) => getScrollPosition(page)(state))

  const scrollRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    const element = scrollRef.current
    if (!element) return

    const handleScroll = () => {
      dispatch(scrollPositionActions.setScrollPosition({
        [page]: element.scrollTop,
      }))
    }

    element.scrollTo(0, position)
    element.addEventListener('scroll', handleScroll)

    return () => {
      element.removeEventListener('scroll', handleScroll)
    }
  }, [position, dispatch, page])

  return scrollRef
}
