import { menuAnatomy as parts } from '@chakra-ui/anatomy'
import { StyleFunctionProps } from '@chakra-ui/react'
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system'
import { runIfFn } from '@chakra-ui/utils'

const { defineMultiStyleConfig, definePartsStyle }
  = createMultiStyleConfigHelpers(parts.keys)

const baseStyleList = defineStyle(() => {
  return {
    bg: 'white',
    color: 'inherit',
    minW: '250px',
    zIndex: 'modal',
    borderRadius: '12px',
    borderWidth: '0px',
    p: 0,
    boxShadow: '0px 0px 32px 0px #00000033',
  }
})

const baseStyleItem = defineStyle(() => {
  return {
    padding: '0px 16px 0px 16px',
    transitionDuration: 'fast',
    transitionTimingFunction: 'ease-in',
    backgroundColor: 'transparent',
    minWidth: '70px',
    minHeight: '44px',
    fontSize: '14px',
    fontWeight: '500',
    borderBottom: '0.5px solid',
    borderColor: 'brand.gray.500',
    _last: {
      borderColor: 'transparent',
    },
    _hover: {},
    _focus: {},
    _active: {
      color: 'brand.blue.500',
    },
    _expanded: {},
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  }
})

const baseStyleGroupTitle = defineStyle({
  fontWeight: 'semibold',
  fontSize: 'sm',
})

const baseStyleCommand = defineStyle({
  opacity: 0.6,
})

const baseStyleDivider = defineStyle({
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  my: '2',
  opacity: 0.6,
})

const baseStyleButton = defineStyle({
  transitionProperty: 'common',
  transitionDuration: 'normal',
  color: 'brand.blue.500',
  fontSize: '14px',
  fontWeight: 'bold',
  _active: {
    color: 'brand.blue.500',
  },
})

const baseStyle = definePartsStyle((props: StyleFunctionProps) => ({
  button: baseStyleButton,
  list: runIfFn(baseStyleList, props as never),
  item: runIfFn(baseStyleItem, props as never),
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
}))

const Menu = defineMultiStyleConfig({
  baseStyle,
})

export default Menu
