import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DimensionSchema } from '../types/DimensionSchema'

const initialState: DimensionSchema = {
  progress: 0,
  strong: 0,
  average: 0,
  weak: 0,
  hotmap: null,
}

export const dimensionSlice = createSlice({
  name: 'dimension',
  initialState,
  reducers: {
    setDimensionProgress: (state, action) => {
      state.progress = action.payload
    },
    setResultGradient: (state, action) => {
      const { strong, average, weak } = action.payload
      state.strong = strong
      state.average = average
      state.weak = weak
    },
    setImageHotmap: (state, action: PayloadAction<string>) => {
      state.hotmap = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: dimensionActions } = dimensionSlice
export const { reducer: dimensionReducer } = dimensionSlice
