const _darkColor = 'whiteAlpha.900'

export const semanticTokens = {
  colors: {
    'primary': { default: 'brand.orange.500', _dark: _darkColor },
    'primary-hover': { default: 'brand.orange.600', _dark: 'brand.gray.500' },
    'secondary': { default: 'brand.gray.200', _dark: _darkColor },
    'secondary-color': { default: 'brand.gray.900', _dark: _darkColor },
    'secondary-hover': { default: 'brand.gray.300', _dark: _darkColor },
    'secondary-border': { default: 'brand.gray.500', _dark: _darkColor },
    'outline-border': { default: 'brand.orange.500', _dark: _darkColor },
    'skeleton-light': {
      default: 'brand.skeletonLight',
    },
    'skeleton-medium': {
      default: 'brand.skeletonMedium',
    },
  },
}
