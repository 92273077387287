import { Image } from '@chakra-ui/react'
import React, { FC, memo, PropsWithChildren } from 'react'
import { getAbbreviatedText } from 'shared/lib/getAbbreviatedText/getAbbreviatedText'
import { Box } from 'shared/ui/Box'
import { Flex } from 'shared/ui/Flex'

interface ApartmentsListItemProps extends PropsWithChildren {
  id: number
  url: string
  squareMeters: number
  numberRooms: number
  onClick: (apartmentId: number) => void
}

const ApartmentsListItem: FC<ApartmentsListItemProps> = ({
  url,
  id,
  squareMeters,
  numberRooms,
  onClick,
}) => {
  return (
    <Box
      onClick={() => onClick(id)}
      cursor="pointer"
      border="2px solid"
      borderColor="brand.gray.500"
      borderRadius="12px"
      p="5px"
      position="relative"
      maxH="266px"
    >
      <Box h="100%" w="100%" align="center">
        <Image h="100%" alt={`image_${url}`} src={url} />
      </Box>
      <Flex
        position="absolute"
        justifyContent="center"
        left={0}
        right={0}
        bottom={30}
        w="100%"
      >
        <Box
          borderRadius="3px"
          p="8px 12px 8px 12px"
          backgroundColor="brand.orange.500"
          color="white"
        >
          {getAbbreviatedText(numberRooms, squareMeters)}
        </Box>
      </Flex>
    </Box>
  )
}

export default memo(ApartmentsListItem)
