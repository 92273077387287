import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle }
  = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  overlay: defineStyle({
    zIndex: 'modal',
  }),
  dialogContainer: defineStyle({}),
  dialog: defineStyle({}),
  header: defineStyle({}),
  closeButton: defineStyle({}),
  body: defineStyle({}),
  footer: defineStyle({}),
})

const defaultStyleDialog = defineStyle({
  maxHeight: '100%',
  // maxHeight: "90vh",
  margin: 0,
  minWidth: '560px',
  borderRadius: 16,
  boxSizing: 'content-box',
})

const defaultStyleHeader = defineStyle({
  marginBottom: '16px',
  padding: 0,
  fontSize: 24,
  fontWeight: 500,
  lineHeight: '120%',
})

const defaultStyleBody = defineStyle({
  'width': '100%',
  'padding': 32,
  'fontWeight': 400,
  'fontSize': 16,
  'lineHeight': '110%',
  'overflow': 'auto',
  '::-webkit-scrollbar': {
    width: '10px',
  },

  '::-webkit-scrollbar-track': {
    bg: 'transparent',
    backgroundClip: 'padding-box',
    border: '4px solid',
    borderColor: 'transparent',
    borderRadius: '999px',
    marginBlock: '6px',
  },

  '::-webkit-scrollbar-thumb': {
    border: '4px solid',
    borderColor: 'transparent',
    backgroundClip: 'padding-box',
    borderRadius: '9999px',
    backgroundColor: 'brand.gray.500',
  },
})

const defaultStyleFooter = defineStyle({
  justifyContent: 'start',
  padding: 0,
  color: 'brand.gray.600',
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '110%',
  marginTop: '16px',
})

const variantDefault = definePartsStyle({
  overlay: defineStyle({
    bg: '#7580987F',
  }),
  dialogContainer: defineStyle({
    alignItems: 'center',
    padding: '48px',
  }),
  dialog: defaultStyleDialog,
  header: defaultStyleHeader,
  closeButton: defineStyle({
    position: 'absolute',
    top: '0',
    insetEnd: '-56px',
  }),
  body: defaultStyleBody,
  footer: defaultStyleFooter,
})

const variantFullscreen = definePartsStyle({
  overlay: defineStyle({
    padding: 0,
    margin: 0,
  }),
  dialogContainer: defineStyle({
    height: '100%',
    padding: 0,
  }),
  dialog: defineStyle({
    margin: 0,
    padding: 0,
    borderRadius: 0,
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
  }),
  header: defineStyle({
    marginTop: 5,
  }),
  closeButton: defineStyle({
    marginTop: '14px',
  }),
  body: defineStyle({
    padding: 0,
    overflow: 'auto',
  }),
  footer: defineStyle({}),
})

const variants = {
  default: variantDefault,
  fullscreen: variantFullscreen,
}

const Modal = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'default',
  },
})

export default Modal
