import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { RoomsRequest, RoomsResponse } from '../../types/ApartmentsTypes'

export const roomsApi = createApi({
  reducerPath: 'roomsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getRooms: builder.query<RoomsResponse, RoomsRequest>({
      query: () => '/flat/rooms',
    }),
  }),
})

export const { useGetRoomsQuery } = roomsApi
