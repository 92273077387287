import { Skeleton } from '@chakra-ui/react'
import React, { memo } from 'react'
import { Flex } from 'shared/ui/Flex'

const ImageSkeleton = () => {
  return (
    <Flex
      h="100%"
      w="100%"
      p={5}
    >
      <Skeleton
        h="100%"
        w="100%"
      />
    </Flex>
  )
}

export default memo(ImageSkeleton)
