import { DIMENSION } from '../../../store/DIMENSION'

export const displayFluctuations = () => {
  if (!DIMENSION.ctx_imgData) return
  let index = 0
  for (let y = 0; y < DIMENSION.NImgy; y++) {
    for (let q = 0; q < 2; q++) {
      for (let x = 0; x < DIMENSION.NImgx; x++) {
        const tPFz = DIMENSION.PFz[x + DIMENSION.takeover / 2][y + DIMENSION.takeover / 2]
        const c = tPFz * 15000
        if (c >= 0) {
          const cd = 255 - Math.sqrt(c)
          for (let r = 0; r < 2; r++) {
            if (DIMENSION.ctx_imgData[index] > 240) {
              DIMENSION.ctx_imgData[index++] = 255
              DIMENSION.ctx_imgData[index++] = cd
              DIMENSION.ctx_imgData[index++] = cd
              index++
            }
            else {
              index += 4
            }
          }
        }
        else {
          const cd = 255 - Math.sqrt(-c)
          for (let r = 0; r < 2; r++) {
            if (DIMENSION.ctx_imgData[index] > 240) {
              DIMENSION.ctx_imgData[index++] = 255
              DIMENSION.ctx_imgData[index++] = cd
              DIMENSION.ctx_imgData[index++] = cd
              index++
            }
            else {
              index += 4
            }
          }
        }
      }
    }
  }
}
