import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { ApartmentDimensionRequest, ApartmentDimensionResponse } from '../../types/ApartmentDimension'

export const apartmentDimensionApi = createApi({
  reducerPath: 'apartmentDimensionApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getApartmentDimension: builder.query<ApartmentDimensionResponse, ApartmentDimensionRequest>({
      query: id => `/flat/${id}`,
    }),
  }),
})

export const { useGetApartmentDimensionQuery } = apartmentDimensionApi
