import {
  forwardRef,
  StepSeparator as ChakraStepSeparator,
  StepSeparatorProps as ChakraStepSeparatorProps,
} from '@chakra-ui/react'
import { FC } from 'react'

export type BaseStepSeparatorProps = ChakraStepSeparatorProps
export const BaseStepSeparator = forwardRef<BaseStepSeparatorProps, 'div'>(
  function BaseStepSeparator(props, ref) {
    return <ChakraStepSeparator ref={ref} {...props} />
  },
)

export type StepProps = BaseStepSeparatorProps

export const StepSeparator: FC<StepProps> = (props) => {
  return (
    <BaseStepSeparator
      {...props}
    />
  )
}
