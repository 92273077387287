import React, { FC } from 'react'

import styles from './LoaderIcon.module.css'

import { Box } from '../../ui/Box'

interface LoaderIconProps {

}

const LoaderIcon: FC<LoaderIconProps> = () => {
  return (
    <Box w="120px" h="120px">
      <figure className={styles.wrapper}>
        <div className={styles.lines}></div>
        <div className={styles.lines}></div>
        <div className={styles.lines}></div>
      </figure>
    </Box>
  )
}

export default LoaderIcon
