import { draw } from './utils/draw'
import { img_walls_to_PFm_PFc } from './utils/img_walls_to_PFm_PFc'
import { new2DFillArray } from './utils/new2DFillArray'

import { DIMENSION } from '../../store/DIMENSION'

export const startListenersDimension = () => {
  // ================== ИНИЦИАЛИЗАЦИЯ и ЦИКЛИЧЕСКИЙ ЗАПУСК ОТРИСОВКИ (draw) ==================
  DIMENSION.img.onload = function () {
    // фоновый рисунок загружен
    // Исходя из максмиального кол-во сегементов определение разбиения холста на тайлы
    DIMENSION.NImgx = Math.round(DIMENSION.img.width / 2) // размерность по X только изображение
    DIMENSION.NImgy = Math.round(DIMENSION.img.height / 2) // размерность по Y только изображение
    DIMENSION.takeover = Math.round(DIMENSION.NImgx * 0.12 / 2) * 2

    // DIMENSION.Nx = 2 * Math.round((0.08 * img.width) / st) + DIMENSION.NImgx; // размерность по X + 8%x2 (улица)
    // DIMENSION.Ny = 2 * Math.round((0.08 * img.height) / st) + DIMENSION.NImgy; // размерность по Y + 8%x2 (улица)
    DIMENSION.Nx = DIMENSION.NImgx // размерность по X + 8%x2 (улица)
    DIMENSION.Ny = DIMENSION.NImgy // размерность по Y + 8%x2 (улица)

    // нахождение для изображения смещени и размер с учетом отступа
    // X0,Y0,Wd,Hg в зависимости от (зазор 3%х2 -> от Xcanvas/(0.93Ycanvas = 7% нижний бар)):
    if (
      DIMENSION.Xcanvas / (0.93 * DIMENSION.Ycanvas)
      > DIMENSION.Nx / DIMENSION.Ny
    ) {
      // зазоры расчитываем по вертикали
      // DIMENSION.Hg = 0.94 * 0.93 * DIMENSION.Ycanvas;
      // DIMENSION.Y0 = 0.03 * DIMENSION.Hg;
      DIMENSION.Hg = DIMENSION.Ycanvas
      DIMENSION.Y0 = 0
      DIMENSION.Wd = (DIMENSION.Hg * DIMENSION.img.width) / DIMENSION.img.height
      DIMENSION.X0 = (DIMENSION.Xcanvas - DIMENSION.Wd) / 2
      DIMENSION.TailSize = DIMENSION.Hg / DIMENSION.Ny
    }
    else {
      // зазоры расчитываем по горизонтали
      // DIMENSION.Wd = 0.94 * DIMENSION.Xcanvas;
      // DIMENSION.X0 = 0.03 * DIMENSION.Wd;
      DIMENSION.Wd = DIMENSION.Xcanvas
      DIMENSION.X0 = 0
      DIMENSION.Hg = (DIMENSION.Wd * DIMENSION.img.height) / DIMENSION.img.width
      // DIMENSION.Y0 = (0.93 * DIMENSION.Ycanvas - DIMENSION.Hg) / 2;
      DIMENSION.Y0 = (DIMENSION.Ycanvas - DIMENSION.Hg) / 2
      DIMENSION.TailSize = DIMENSION.Wd / DIMENSION.Nx
    }

    // canvas1 - холст распространнения волн, ctx1_imgData массив 832х842
    if (!DIMENSION.ctx || !DIMENSION.canvasElement) {
      return
    }

    DIMENSION.ctx.drawImage(DIMENSION.img, 0, 0)
    DIMENSION.imgData = DIMENSION.ctx.getImageData(
      0,
      0,
      DIMENSION.img.width,
      DIMENSION.img.height,
    )
    DIMENSION.ctx_imgData = DIMENSION.imgData.data

    // hotmap
    DIMENSION.imgData_hotmap = DIMENSION.ctx.getImageData(
      0,
      0,
      DIMENSION.img.width,
      DIMENSION.img.height,
    )
    DIMENSION.ctx_imgData_hotmap = DIMENSION.imgData_hotmap.data

    DIMENSION.PFz = new2DFillArray(
      DIMENSION.NImgx + DIMENSION.takeover,
      DIMENSION.NImgy + DIMENSION.takeover,
      0,
    ) // Z coordinates
    DIMENSION.PFvz = new2DFillArray(
      DIMENSION.NImgx + DIMENSION.takeover,
      DIMENSION.NImgy + DIMENSION.takeover,
      0,
    ) // Z speed
    DIMENSION.PFaz = new2DFillArray(
      DIMENSION.NImgx + DIMENSION.takeover,
      DIMENSION.NImgy + DIMENSION.takeover,
      0,
    ) // Z acceleration
    // http://www.andrewnoske.com/wiki/Code_-_heatmaps_and_color_gradients
    DIMENSION.PFheatmap = new2DFillArray(
      DIMENSION.NImgx + DIMENSION.takeover,
      DIMENSION.NImgy + DIMENSION.takeover,
      -1000,
    )

    DIMENSION.PFm = new2DFillArray(DIMENSION.Wd, DIMENSION.Hg, 80)
    DIMENSION.PFc = new2DFillArray(DIMENSION.Wd, DIMENSION.Hg, 0.5)

    img_walls_to_PFm_PFc() // отчистка PFz, PFvz, PFaz, PFm и PFc и перезаполенение PFm и PFc
    draw()
  }
}
