import {
  forwardRef,
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from '@chakra-ui/react'

export type Props = ChakraTextProps

const Text = forwardRef<Props, 'p'>(function Text(props, ref) {
  return <ChakraText ref={ref} {...props} />
})

export default Text
