import {
  forwardRef,
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from '@chakra-ui/react'

export type Props = ChakraIconProps
const Icon = forwardRef<Props, 'svg'>(function Icon(props, ref) {
  return <ChakraIcon ref={ref} {...props} />
})

export default Icon
