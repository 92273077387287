import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PageDescriptionSchema } from '../types/PageDescription'

const initialState: PageDescriptionSchema = {
  visible: false,
  description: '',
  isLoading: true,
}

export const pageDescriptionSlice = createSlice({
  name: 'pageDescription',
  initialState,
  reducers: {
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: pageDescriptionActions } = pageDescriptionSlice
export const { reducer: pageDescriptionReducer } = pageDescriptionSlice
