import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const gray200 = 'brand.gray.200'

const { definePartsStyle, defineMultiStyleConfig }
  = createMultiStyleConfigHelpers([...parts.keys, 'label'])

const baseStyle = definePartsStyle({
  field: {
    'color': 'brand.gray.900',
    'fontStyle': 'normal',
    'lineHeight': '110%',
    '-webkit-appearance': 'none',
  },
})

export const size = {
  lg: {
    fontSize: 'md',
    p: '10px 20px 10px 20px',
    h: '52px',
    borderRadius: '12px',
  },
  md: {
    fontSize: '14px',
    p: '10px 20px 10px 20px',
    h: '40px',
    borderRadius: '12px',
  },
}

const sizes = {
  lg: definePartsStyle({
    field: size.lg,
    addon: size.lg,
    element: {
      _focusVisible: {
        boxShadow: '0 0 0 2px rgba(255, 105, 0, 0.5)', // Add shadow on focus
      },
    },
  }),
  md: definePartsStyle((props) => {
    return {
      field: {
        ...size.md,
        paddingTop: props?.label ? '14px !important' : 'unset',
      },
      addon: size.md,
      element: {
        'height': '38px',
        '& > button': {
          minWidth: 'unset',
          width: '38px',
          height: '38px',
        },
        '_focusVisible': {
          boxShadow: '0 0 0 2px rgba(255, 105, 0, 0.5)', // Add shadow on focus
        },
      },
      label: {
        fontSize: '14px',
        margin: '12px !important',
      },
    }
  }),
}

export function getDefaults(props: Record<string, unknown>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props
  return {
    focusBorderColor: fc || 'blue.500',
    errorBorderColor: ec || 'red.500',
  }
}

const variantBase = definePartsStyle((props) => {
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props)

  return {
    field: {
      'fontWeight': 400,
      'border': '1px solid',
      'borderColor': 'brand.gray.400',
      'bg': gray200,
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      '::placeholder': {
        color: 'brand.gray.600',
        /* Firefox */
        opacity: 1,
      },

      '::-ms-input-placeholder': {
        /* Microsoft Edge */ color: 'brand.gray.600',
      },
      '_hover': {
        borderColor: gray200,
      },
      '_readOnly': {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      '_invalid': {
        borderColor: ec,
        boxShadow: `0 0 0 1px ${ec}`,
      },
      '_focusVisible': {
        zIndex: 1,
        borderColor: 'brand.orange.500',
        boxShadow: `0 0 0 1px ${fc}`,
        _invalid: {
          borderColor: ec,
          boxShadow: `0 0 0 1px ${ec}`,
        },
      },
      '_disabled': { opacity: 1, backgroundColor: 'brand.gray.300' },
    },
    addon: {
      border: '1px solid',
      borderColor: gray200,
      bg: gray200,
    },
    element: {
      cursor: 'pointer',
      overflow: 'hidden',
      right: '15px',
      borderRadius: '100%',
      padding: '15px',
      marginTop: '1px',
      _focusVisible: {
        boxShadow: '0 0 0 2px rgba(255, 105, 0, 0.5)', // Add shadow on focus
      },
    },
    label: {},
  }
})

const Input = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: { base: variantBase },
  defaultProps: {
    variant: 'base',
    size: 'lg',
  },
})

export default Input
