import {
  forwardRef,
  InputElementProps as ChakraInputElementProps,
  InputGroup as ChakraInputGroup,
  InputGroupProps as ChakraInputGroupProps,
  InputLeftElement as ChakraInputLeftElement,
  InputRightElement as ChakraInputRightElement,
} from '@chakra-ui/react'

export type InputElementProps = ChakraInputElementProps

export const InputRightElement = forwardRef<InputElementProps, 'div'>(
  function InputRightElement(props, ref) {
    return <ChakraInputRightElement ref={ref} {...props} />
  },
)

export const InputLeftElement = forwardRef<InputElementProps, 'div'>(
  function InputLeftElement(props, ref) {
    return <ChakraInputLeftElement ref={ref} {...props} />
  },
)

export type InputGroupProps = ChakraInputGroupProps

const InputGroup = forwardRef<InputGroupProps, 'div'>(function InputGroup(
  props,
  ref,
) {
  return <ChakraInputGroup ref={ref} {...props} />
})

export default InputGroup
