import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { UserInfoRequest, UserInfoResponse } from '../../types/user'

export const userProfileApi = createApi({
  reducerPath: 'userProfileApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getUserInfo: builder.query<UserInfoResponse, UserInfoRequest>({
      query: () => '/user/profile',
    }),
  }),
})

export const { useGetUserInfoQuery } = userProfileApi
