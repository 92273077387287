import { yupResolver } from '@hookform/resolvers/yup'
import { userActions } from 'entities/User'
import { toastActions } from 'features/toast'
import React, { memo, useCallback, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  POPUP_LOCALSTORAGE_SHOW_ONBOARDING,
  USER_LOCALSTORAGE_TOKEN_KEY,
} from 'shared/const/localstorage'
import { RoutePath } from 'shared/const/route'
import { HidePasswordIcon } from 'shared/icons/HidePasswordIcon'
import { LogoIcon } from 'shared/icons/LogoIcon'
import { ShowPasswordIcon } from 'shared/icons/ShowPasswordIcon'
import { FetchError } from 'shared/lib/baseQueryWithReauth/baseQueryWithReauth'
import {
  DynamicModuleLoader,
  ReducersList,
} from 'shared/lib/components/DynamicModuleLoader'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Box } from 'shared/ui/Box'
import { Button } from 'shared/ui/Button'
import { Flex } from 'shared/ui/Flex'
import { FormControl } from 'shared/ui/FormControl'
import { Input } from 'shared/ui/Input'
import { InputGroup, InputRightElement } from 'shared/ui/InputGroup'
import { Text } from 'shared/ui/Text'
import * as yup from 'yup'

import { authApi, useLoginMutation } from '../../model/services/login/login'

interface FormValues {
  username: string
  password: string
}

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required()

const initialReducers: ReducersList = {
  [authApi.reducerPath]: authApi.reducer,
}

const LoginForm = () => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'onChange',
  })
  const [login, { isLoading }] = useLoginMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async ({ username, password }) => {
      try {
        const { data, error } = await login({ username, password })
        if (data) {
          const { access_token, is_onboarded } = data.data
          localStorage.setItem(USER_LOCALSTORAGE_TOKEN_KEY, access_token)
          localStorage.setItem(
            POPUP_LOCALSTORAGE_SHOW_ONBOARDING,
            is_onboarded ? 'yes' : 'no',
          )
          dispatch(
            toastActions.setToast({
              text: 'Авторизация прошла успешно',
              status: 'success',
              delay: 5000,
            }),
          )
          dispatch(userActions.setIsAuth(true))
          navigate(RoutePath.main)
        }
        else {
          dispatch(
            toastActions.setToast({
              text:
                (error as FetchError).data?.message || 'Что-то пошло не так',
              status: 'error',
              delay: 5000,
            }),
          )
        }
      }
      catch (e) {
        dispatch(
          toastActions.setToast({
            text: 'Что-то пошло не так',
            status: 'error',
            delay: 5000,
          }),
        )
      }
    },
    [dispatch, navigate, login],
  )

  return (
    <DynamicModuleLoader removeAfterUnmount reducers={initialReducers}>
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Flex onSubmit={handleSubmit(onSubmit)} as="form" w="100%" h="100%">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            w="100%"
            h="100%"
            gap="32px"
            p="32px 30px 32px 30px"
          >
            <LogoIcon />
            <Text fontSize="22px">Вход</Text>
            <Controller
              control={control}
              name="username"
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <Input
                      type="text"
                      className=""
                      placeholder="Логин"
                      {...field}
                    />
                  </FormControl>
                )
              }}
            />
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <InputGroup>
                      <Input
                        type={!showPassword ? 'password' : 'text'}
                        className=""
                        placeholder="Пароль"
                        {...field}
                      />
                      <InputRightElement>
                        <Button
                          variant="link"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword
                            ? (
                                <ShowPasswordIcon />
                              )
                            : (
                                <HidePasswordIcon />
                              )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )
              }}
            />
            <Button
              type="submit"
              isLoading={isLoading}
              loadingText="Вход"
              w="100%"
            >
              Вход
            </Button>
          </Box>
        </Flex>
      </Flex>
    </DynamicModuleLoader>
  )
}

export default memo(LoginForm)
