const radii = {
  4: '4px',
  6: '6px',
  8: '8px',
  12: '12px',
  16: '16px',
  24: '24px',
  full: '9999px',
}

export default radii
