import {
  configureStore,
  EnhancedStore,
  ReducersMapObject,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { apartmentApi, apartmentsApi, roomsApi } from 'entities/Apartment'
import { apartmentDimensionApi, bestResultApi } from 'entities/Dimension'
import { linkApi } from 'entities/Link'
import { popupReducer } from 'entities/Popup'
import { userProfileApi, userReducer } from 'entities/User'
import { authApi } from 'features/auth'
import { lastSearchParamsSliceReducer } from 'features/backButton'
import { dimensionStepsReducer } from 'features/dimensionSteps'
import { onboardingCompleteApi, onboardingListApi } from 'features/onboarding'
import { pageDescriptionReducer } from 'features/pageDescription'
import { saveResultApi } from 'features/saveResult'
import { scrollPositionReducer } from 'features/scroll'
import { sortMenuReducer } from 'features/sortMenu'
import { toastReducer } from 'features/toast'

import { createReducerManager } from './reducerManager'

import { ReducerManager, StateSchema } from '../types/StateSchema'

const staticReducers: ReducersMapObject<StateSchema> = {
  user: userReducer,
  popup: popupReducer,
  scrollPosition: scrollPositionReducer,
  [userProfileApi.reducerPath]: userProfileApi.reducer,
  [linkApi.reducerPath]: linkApi.reducer,
  dimensionSteps: dimensionStepsReducer,
  toast: toastReducer,
  sortMenu: sortMenuReducer,
  pageDescription: pageDescriptionReducer,
  lastSearchParams: lastSearchParamsSliceReducer,
}

const reducerManager = createReducerManager(staticReducers)

// Define the store with reducerManager
const store = configureStore({
  reducer: reducerManager.reduce,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware(
      {
        serializableCheck: false,
      },
    ).concat(
      authApi.middleware,
      apartmentsApi.middleware,
      userProfileApi.middleware,
      onboardingListApi.middleware,
      onboardingCompleteApi.middleware,
      apartmentApi.middleware,
      apartmentDimensionApi.middleware,
      roomsApi.middleware,
      bestResultApi.middleware,
      saveResultApi.middleware,
      linkApi.middleware,
    ),
})

// Type definition for the store with reducerManager
interface EnhancedStoreWithReducerManager extends EnhancedStore<StateSchema> {
  reducerManager: ReducerManager
}

// Cast store to the enhanced type
const enhancedStore = store as EnhancedStoreWithReducerManager
enhancedStore.reducerManager = reducerManager

setupListeners(store.dispatch)

export default enhancedStore

export type AppDispatch = typeof enhancedStore.dispatch
