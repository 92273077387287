import {
  forwardRef,
  StepStatus as ChakraStepStatus,
  StepStatusProps as ChakraStepStatusProps,
} from '@chakra-ui/react'
import { FC } from 'react'

export type BaseStepStatusProps = ChakraStepStatusProps
export const BaseStepStatus = forwardRef<BaseStepStatusProps, 'div'>(
  function BaseStepStatus(props, _) {
    return <ChakraStepStatus {...props} />
  },
)

export type StepStatusProps = BaseStepStatusProps

export const StepStatus: FC<StepStatusProps> = (props) => {
  return (
    <BaseStepStatus
      {...props}
    />
  )
}
