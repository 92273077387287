const red = '#E31A1A'

const bulletGreen = '#4EB8A8'
const lightGreen = '#B4EFDC'

const violet = '#874DBF'
const lightViolet = '#D2B4EA'

const skeletonLight = '#ECEFF4'
const skeletonMedium = '#E2E6EE'

const yellow = '#F7FF14'

const green = {
  100: '#00d162',
  200: '#60DA00',
  300: '#12A356',
  400: '#12A356',
  500: '#12A356',
  600: '#12A356',
  700: '#12A356',
}

const blue = {
  100: '#EBF1FC',
  200: '#D7E4F8',
  300: '#B3DCF9',
  400: '#64B4F0',
  500: '#007AFF',
  600: '#326CC8',
  700: '#0078E6',
}

const orange = {
  200: '#FFEEE9',
  400: '#FFB990',
  500: '#FE5000',
  600: '#e94802',
}

const gray = {
  50: '#F2F5FA',
  100: '#FAFBFC',
  200: '#F5F7FA',
  300: '#ECEFF3',
  400: '#E6EAEF',
  500: '#C8CDD7',
  550: '#b8bdc6',
  600: '#758098',
  900: '#090C13',
}

const colors = {
  brand: {
    orange,

    red,

    blue,

    bulletGreen,
    green,
    lightGreen,

    violet,
    lightViolet,

    skeletonLight,
    skeletonMedium,

    gray,

    yellow,
  },
}

export default colors
