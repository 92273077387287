import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { LinkRequest, LinkResponse } from '../../types/link'

export const linkApi = createApi({
  reducerPath: 'linkApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getLink: builder.query<LinkResponse, LinkRequest>({
      query: id => `/setting-url/${id}`,
    }),
  }),
})

export const { useGetLinkQuery } = linkApi
