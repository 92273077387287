import {
  forwardRef,
  Step as ChakraStep,
  StepProps as ChakraStepProps,
} from '@chakra-ui/react'
import { FC } from 'react'

export type BaseStepProps = ChakraStepProps
export const BaseStep = forwardRef<BaseStepProps, 'div'>(
  function BaseStep(props, ref) {
    return <ChakraStep ref={ref} {...props} />
  },
)

export type StepProps = BaseStepProps

export const Step: FC<StepProps> = (props) => {
  return (
    <BaseStep
      {...props}
    />
  )
}
