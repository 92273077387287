import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { ApartmentRequest, ApartmentResponse } from '../../types/ApartmentsTypes'

export const apartmentApi = createApi({
  reducerPath: 'apartmentApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getApartment: builder.query<ApartmentResponse, ApartmentRequest>({
      query: id => `/flat/${id}`,
    }),
  }),
})

export const { useGetApartmentQuery } = apartmentApi
