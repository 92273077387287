import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
  forwardRef,
} from '@chakra-ui/react'

export type Props = ChakraFormLabelProps

const FormLabel = forwardRef<Props, 'label'>(function FormLabel(props, ref) {
  return <ChakraFormLabel ref={ref} {...props} />
})

export default FormLabel
