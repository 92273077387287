interface ParamsImage {
  width: number
  height: number
}

export const resizeImage = (img: string, { width, height }: ParamsImage, base64: boolean = false) => {
  return new Promise((resolve, reject) => {
    const img_walls = new Image()
    img_walls.crossOrigin = 'anonymous'
    img_walls.src = img

    img_walls.onload = () => {
    // Создание canvas для изменения размера изображения
      const canvasImage = document.createElement('canvas')
      const contextImage = canvasImage.getContext('2d', { willReadFrequently: true })
      // Установка новых размеров canvas
      canvasImage.width = width
      canvasImage.height = height
      // Масштабирование изображения
      contextImage?.drawImage(img_walls, 0, 0, width, height)

      // Получение нового уменьшенного изображения
      const resizedImageUrl = canvasImage.toDataURL()

      if (base64) {
        resolve(resizedImageUrl)
      }

      // Вы можете использовать resizedImageUrl как источник для нового изображения
      const resizedImage = new Image()
      resizedImage.src = resizedImageUrl
      resizedImage.crossOrigin = 'anonymous'
      resolve(resizedImage)
    }

    img_walls.onerror = (error) => {
      reject(error)
    }
  })
}
