import React, { FC, memo, useCallback } from 'react'
import { ExitIcon } from 'shared/icons/ExitIcon'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Button, ButtonProps } from 'shared/ui/Button'

import { userActions } from '../../model/slice/userSlice'

const LogoutButton: FC<ButtonProps> = (props) => {
  const dispatch = useAppDispatch()
  const handleClickLogout = useCallback(() => {
    dispatch(userActions.logout())
  }, [dispatch])

  return (
    <Button
      variant="link"
      onClick={handleClickLogout}
      {...props}
    >
      <ExitIcon />
    </Button>
  )
}

export default memo(LogoutButton)
