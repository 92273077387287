import { FC, memo, ReactElement, useLayoutEffect } from 'react'

import SkeletonImage from './Skeleton/SkeletonImage'

import { useBoundingRect } from '../../../lib/hooks/useBoundingRect'
import { Box } from '../../Box'

interface SliderProps {
  initSliderWidth: (width: number) => void
  isLoading: boolean
  children?: ReactElement
}

const Slider: FC<SliderProps> = ({
  initSliderWidth,
  children,
  isLoading,
}) => {
  const [ref, { width }] = useBoundingRect()

  useLayoutEffect(() => {
    if (width) {
      initSliderWidth(Math.round(width))
    }
  }, [width, initSliderWidth])

  return (
    <Box
      ref={ref}
      w="100%"
      h={isLoading ? '100%' : ''}
      position="relative"
      overflow="hidden"
    >
      {width ? children : <SkeletonImage />}
    </Box>
  )
}

export default memo(Slider)
