import { Skeleton } from '@chakra-ui/react'
import React, { memo, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import {
  AppRoutes,
  RoutePath,
  ROUTES_WITH_DESCRIPTION,
} from 'shared/const/route'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Text } from 'shared/ui/Text'

import { getDescription } from '../model/selectors/getDescription/getDescription'
import { getIsLoading } from '../model/selectors/getIsLoading/getIsLoading'
import { getVisible } from '../model/selectors/getVisible/getVisible'
import { pageDescriptionActions } from '../model/slice/pageDescriptionSlice'

const PageDescription = () => {
  const location = useLocation()
  const isVisible = useSelector(getVisible)
  const description = useSelector(getDescription)
  const isLoading = useSelector(getIsLoading)
  const dispath = useAppDispatch()

  const currentRoute = Object.keys(RoutePath).find((key) => {
    const path = RoutePath[key as AppRoutes]
    return matchPath(path, location.pathname)
  })

  useLayoutEffect(() => {
    dispath(
      pageDescriptionActions.setVisible(
        ROUTES_WITH_DESCRIPTION.includes(currentRoute as AppRoutes),
      ),
    )
  }, [dispath, currentRoute])

  if (!isVisible) {
    return null
  }

  return (
    <React.Fragment>
      {isLoading
        ? (
            <Skeleton w="100%" h="20px" />
          )
        : (
            <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
              {description}
            </Text>
          )}
    </React.Fragment>
  )
}

export default memo(PageDescription)
