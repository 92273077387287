import {
  forwardRef,
  Stepper as ChakraStepper,
  StepperProps as ChakraStepperProps,
} from '@chakra-ui/react'
import { FC } from 'react'

export type BaseStepperProps = ChakraStepperProps
export const BaseStepper = forwardRef<BaseStepperProps, 'div'>(
  function BaseStepper(props, ref) {
    return <ChakraStepper ref={ref} {...props} />
  },
)

export type StepperProps = BaseStepperProps

export const Stepper: FC<StepperProps> = (props) => {
  return (
    <BaseStepper
      {...props}
    />
  )
}
