import { Image } from '@chakra-ui/react'
import { getVisibleCloseButton, popupActions } from 'entities/Popup'
import React, { FC, memo, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { POPUP_LOCALSTORAGE_SHOW_ONBOARDING } from 'shared/const/localstorage'
import {
  DynamicModuleLoader,
  ReducersList,
} from 'shared/lib/components/DynamicModuleLoader'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Carousel } from 'shared/ui/Carousel'
import { Flex } from 'shared/ui/Flex'

import {
  onboardingCompleteApi,
  useCompleteMutation,
} from '../../model/service/onboardingComplete/onboardingComplete'
import {
  onboardingListApi,
  useGetOnboardingListQuery,
} from '../../model/service/onboardingList/onboardingList'
import ImageSkeleton from '../skeleton/ImageSkeleton'

export interface OnboardingPopupProps {}

const initialReducers: ReducersList = {
  [onboardingListApi.reducerPath]: onboardingListApi.reducer,
  [onboardingCompleteApi.reducerPath]: onboardingCompleteApi.reducer,
}

const OnboardingPopup: FC<OnboardingPopupProps> = () => {
  const { data, isLoading } = useGetOnboardingListQuery({})
  const visibleCloseButton = useSelector(getVisibleCloseButton)
  const dispatch = useAppDispatch()
  const [complete] = useCompleteMutation()
  const onCloseModal = useCallback(async () => {
    try {
      const { data } = await complete({})
      if (data) {
        const { is_onboarded } = data.data
        localStorage.setItem(
          POPUP_LOCALSTORAGE_SHOW_ONBOARDING,
          is_onboarded ? 'yes' : 'no',
        )
        dispatch(popupActions.setIsOpen(false))
      }
    }
    catch (e) {
      console.error(e)
    }
  }, [dispatch, complete])
  const content = useMemo(() => {
    if (isLoading) {
      return [1, 2, 3, 4].map((_, index) => <ImageSkeleton key={index} />)
    }
    return data?.data.list?.map(({ url }, index) => (
      <Flex key={index} w="100%">
        <Image
          src={url}
          alt={`img_${index}`}
          w="100%"
          maxH="88vh"
          objectFit="contain"
        />
      </Flex>
    ))
  }, [isLoading, data])

  return (
    <DynamicModuleLoader removeAfterUnmount reducers={initialReducers}>
      <Carousel
        visibleCloseButton={visibleCloseButton}
        onFinish={onCloseModal}
        isLoading={isLoading}
      >
        {content}
      </Carousel>
    </DynamicModuleLoader>
  )
}

export default memo(OnboardingPopup)
