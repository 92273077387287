import { forwardRef } from '@chakra-ui/react'
import { memo } from 'react'

import { Icon, IconProps } from '../../ui/Icon'

export type Props = IconProps
const CompleteIcon = forwardRef<Props, 'svg'>(function CompleteIcon(
  { width = '12px', height = '12px', ...props },
  ref,
) {
  return (
    <Icon viewBox="0 0 22 22" w={width} h={height} ref={ref} {...props}>
      <path d="M11.002 21.4499C9.55663 21.4499 8.1981 21.1757 6.92634 20.6269C5.65458 20.0781 4.54832 19.3342 3.60758 18.3959C2.66683 17.4577 1.92292 16.3514 1.37413 15.0796C0.825342 13.8079 0.551148 12.4494 0.551148 11.0039C0.551148 9.55853 0.825304 8.19999 1.37409 6.92824C1.92288 5.65648 2.66676 4.55022 3.60501 3.60947C4.54322 2.66866 5.6495 1.92475 6.92124 1.37596C8.19302 0.827172 9.55155 0.552979 10.9969 0.552979C12.4422 0.552979 13.8008 0.827129 15.0726 1.37591C16.3443 1.9247 17.4505 2.66858 18.3913 3.60683C19.3321 4.54508 20.076 5.65136 20.6248 6.92314C21.1736 8.1949 21.4477 9.55343 21.4477 10.9987C21.4477 12.444 21.1735 13.8026 20.6247 15.0743C20.0759 16.346 19.332 17.4523 18.3938 18.393C17.4556 19.3338 16.3493 20.0777 15.0775 20.6265C13.8057 21.1753 12.4472 21.4499 11.002 21.4499ZM9.43896 15.6792L16.8342 8.28394L15.6751 7.1249L9.43896 13.361L6.30347 10.2255L5.14443 11.3845L9.43896 15.6792Z" fill="none" />
      <path d="M9.43896 15.6792L16.8342 8.28394L15.6751 7.1249L9.43896 13.361L6.30347 10.2255L5.14443 11.3845L9.43896 15.6792Z" fill="white" />
    </Icon>
  )
})

export default memo(CompleteIcon)
