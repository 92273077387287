interface IParams {
  requiredQueryParams?: Record<string, string>
}

export const getQueryParams = (
  searchParams: URLSearchParams,
  { requiredQueryParams }: IParams,
) => {
  const queryParams: Record<string, string> = {}

  if (requiredQueryParams) {
    Object.entries(requiredQueryParams).forEach(([key, value]) => {
      queryParams[key] = value
    })
  }

  searchParams.forEach((value, key) => {
    queryParams[key] = value
  })
  return queryParams
}
