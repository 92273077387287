import { enhancedStore } from 'app/providers/storeProvider'

import { dimensionActions } from '../../../slice/dimensionSlice'
import { DIMENSION } from '../../../store/DIMENSION'

export const setProgressBar = () => {
  const currentTime = Date.now()
  const elapsed = currentTime - DIMENSION.lastUpdateProgress
  if (elapsed < 50) {
    return
  }
  DIMENSION.lastUpdateProgress = currentTime
  if (DIMENSION.PCounter++ / 7 >= 100) {
    DIMENSION.end = true
    return
  }
  enhancedStore.dispatch(
    dimensionActions.setDimensionProgress(
      Math.round(DIMENSION.PCounter++ / 7),
    ),
  )
}
