import { createSlice } from '@reduxjs/toolkit'

import { DimensionStepsSchema } from '../types/DimensionStepsSchema'

const initialState: DimensionStepsSchema = {
  visible: false,
  steps: [1, 2, 3, 4],
  activeStep: 0,
}

export const dimensionStepsSlice = createSlice({
  name: 'dimensionSteps',
  initialState,
  reducers: {
    setVisible: (state, action) => {
      state.visible = action.payload
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: dimensionStepsActions } = dimensionStepsSlice
export const { reducer: dimensionStepsReducer } = dimensionStepsSlice
