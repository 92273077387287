import { DIMENSION } from '../../../store/DIMENSION'

export const setPFaz = (x: number, y: number, cen: number) => {
  // neural network processing #1
  DIMENSION.PFaz[x][y]
          = DIMENSION.PFz[x][y - 1]
          + DIMENSION.PFz[x][y + 1]
          + DIMENSION.PFz[x - 1][y]
          + DIMENSION.PFz[x + 1][y]
          + DIMENSION.PFz[x - 1][y - 1]
          + DIMENSION.PFz[x + 1][y + 1]
          + DIMENSION.PFz[x - 1][y + 1]
          + DIMENSION.PFz[x + 1][y - 1]
          - 8 * cen
          - DIMENSION.PFvz[x][y] * DIMENSION.PFc[x][y]
  DIMENSION.PFaz[x][y] = DIMENSION.PFaz[x][y] / DIMENSION.PFm[x][y]
  DIMENSION.PFvz[x][y] += DIMENSION.PFaz[x][y] * DIMENSION.TS
}
