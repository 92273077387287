import {
  Flex as ChakraFlex,
  FlexProps as ChakraFlexProps,
  forwardRef,
} from '@chakra-ui/react'
import { memo } from 'react'

export type Props = ChakraFlexProps
const Flex = forwardRef<Props, 'div'>(function Flex(props, ref) {
  return <ChakraFlex ref={ref} {...props} />
})

export default memo(Flex)
