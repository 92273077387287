import { progressAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system'
import { runIfFn } from '@chakra-ui/utils'

const { defineMultiStyleConfig, definePartsStyle }
  = createMultiStyleConfigHelpers([...parts.keys, 'container'])

const baseStyleContainer = defineStyle({
  height: '2px',
})

const baseStyleLabel = defineStyle({})

const baseStyleTrack = defineStyle({
  borderRadius: '41px',
  backgroundColor: 'brand.gray.300',
})

const baseStyleFilledTrack = defineStyle(() => {
  return {
    backgroundColor: 'brand.green.500',
    borderRadius: 0,
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '100ms',
  }
})

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
  label: baseStyleLabel,
  track: baseStyleTrack,
  filledTrack: runIfFn(baseStyleFilledTrack),
})

const variants = {
  animated: defineStyle({
    container: {
      height: '4px',
    },
    filledTrack: {
      backgroundColor: 'brand.orange.500',
    },
  }),
}

const Progress = defineMultiStyleConfig({
  baseStyle,
  variants,
})

export default Progress
