import { new2DFillArray } from './new2DFillArray'

import { DIMENSION } from '../../../store/DIMENSION'

// +++ функция отчистка PFz, PFvz, PFaz, PFm и PFc и перезаполенение PFm и PFc ===
export const img_walls_to_PFm_PFc = () => {
  // отчистка 466 х 471  (416 x 421)
  DIMENSION.PFz = new2DFillArray(
    DIMENSION.NImgx + DIMENSION.takeover,
    DIMENSION.NImgy + DIMENSION.takeover,
    0,
  ) // Z coordinates
  DIMENSION.PFvz = new2DFillArray(
    DIMENSION.NImgx + DIMENSION.takeover,
    DIMENSION.NImgy + DIMENSION.takeover,
    0,
  ) // Z speed
  DIMENSION.PFaz = new2DFillArray(
    DIMENSION.NImgx + DIMENSION.takeover,
    DIMENSION.NImgy + DIMENSION.takeover,
    0,
  ) // Z acceleration

  // PFm  = new2DFillArray(466,471, 80.);          // масса основное и архивны поля
  // PFc  = new2DFillArray(466,471, 15.);          // диссипативное поле

  // перевод img_walls в PFm и PFc
  // создание canvas и нанесение на него img_walls (определение стен)
  const tmp_canvas = document.createElement('canvas')
  tmp_canvas.width = DIMENSION.NImgx
  tmp_canvas.height = DIMENSION.NImgy

  const tmp_ctx = tmp_canvas.getContext('2d', { willReadFrequently: true })
  if (!tmp_ctx) {
    return
  }

  tmp_ctx.imageSmoothingQuality = 'high' // размытие "low", "medium", "high"
  tmp_ctx.drawImage(DIMENSION.img, 0, 0, DIMENSION.NImgx, DIMENSION.NImgy)
  // преобразование canvas+img в array mass for PFm
  for (let x = 0; x < DIMENSION.NImgx; x++) {
    for (let y = 0; y < DIMENSION.NImgy; y++) {
      const data = tmp_ctx.getImageData(x, y, 1, 1).data
      // 255 - 0(черный)..255(белый) плюс базовая масс tail = 80.0
      const gr
            = 255
            - parseInt(
              (data[0] * 0.299 + data[1] * 0.587 + data[2] * 0.114).toString(),
            ) // Википедия (Лума)
      if (gr > 20) {
        DIMENSION.PFm[x + DIMENSION.takeover / 2][y + DIMENSION.takeover / 2] = 80.0 + gr
        DIMENSION.PFc[x + DIMENSION.takeover / 2][y + DIMENSION.takeover / 2] = 10 // gr/6.;
      }
    }
  }
}
