import {
  FormHelperText as ChakraFormHelperText,
  forwardRef,
  TextProps as ChakraFormHelpTextProps,
} from '@chakra-ui/react'

export type Props = ChakraFormHelpTextProps

const FormHelperText = forwardRef<Props, 'div'>(function FormErrorMessage(
  props,
  ref,
) {
  return <ChakraFormHelperText ref={ref} {...props} />
})

export default FormHelperText
