import React, { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  AppRoutes,
  ROADS,
  RoutePath,
  ROUTES_WITH_RELOAD,
} from 'shared/const/route'
import { BackIcon } from 'shared/icons/BackIcon'
import { Button } from 'shared/ui/Button'

import { getLastSearchParams } from '../model/selectors/getLastSearchParams/getLastSearchParams'

const BackButton = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const isMainPage = location.pathname === RoutePath.main

  const currentRoute = Object.keys(RoutePath).find((key) => {
    const path = RoutePath[key as AppRoutes]
    return matchPath(path, location.pathname)
  })

  const lastSearchParams = useSelector(getLastSearchParams)

  const handleClickBack = useCallback(() => {
    const backPage = ROADS.get(currentRoute as AppRoutes)?.backPage
    let lastSearchParamsPage: URLSearchParams

    if (backPage && backPage.url) {
      lastSearchParamsPage = lastSearchParams[backPage.url]
      let queryParams = ''
      lastSearchParamsPage?.forEach((value, key) => {
        queryParams += `${queryParams === '' ? '?' : '&'}${key}=${value}`
      })

      if (backPage.requiredId) {
        ROUTES_WITH_RELOAD.includes(currentRoute as AppRoutes)
          ? (window.location.href = `/${backPage.url}/${id}${queryParams}`)
          : navigate(`/${backPage.url}/${id}${queryParams}`)
      }
      else {
        ROUTES_WITH_RELOAD.includes(currentRoute as AppRoutes)
          ? (window.location.href = `/${backPage.url}${queryParams}`)
          : navigate(`/${backPage.url}${queryParams}`)
      }
    }
  }, [navigate, currentRoute, id, lastSearchParams])

  if (isMainPage) {
    return null
  }

  return (
    <Button
      leftIcon={<BackIcon />}
      fontSize="15px"
      fontWeight="bold"
      color="brand.blue.500"
      onClick={handleClickBack}
      h="100%"
      variant="link"
    >
      Назад
    </Button>
  )
}

export default memo(BackButton)
