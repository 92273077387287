import React, {
  FC,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import CarouselIndicator from './CarouselIndicator/CarouselIndicator'
import Item from './Item/Item'
import ManagementButtons from './ManagementButtons/ManagementButtons'
import Slider from './Slider/Slider'
import Track from './Track/Track'

import { Box } from '../Box'
import { CloseButton } from '../CloseButton'
import { Flex } from '../Flex'
import { HStack } from '../Stack'

interface CarouselProps {
  children?: ReactElement[]
  onFinish?: () => void
  visibleCloseButton?: boolean
  isLoading?: boolean
}

const Carousel: FC<CarouselProps> = ({ children, onFinish, visibleCloseButton = true, isLoading = false }) => {
  const [trackIsActive, setTrackIsActive] = useState(false)
  const [sliderWidth, setSliderWidth] = useState(0)
  const [activeItem, setActiveItem] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)

  const initSliderWidth = useCallback(
    (width: number) => setSliderWidth(width),
    [],
  )

  const positions = useMemo(
    () => children?.map((_, index) => -Math.abs(itemWidth * index)) || [],
    [children, itemWidth],
  )

  useEffect(() => {
    setItemWidth(sliderWidth)
  }, [sliderWidth])

  const sliderProps = {
    setTrackIsActive,
    initSliderWidth,
    setActiveItem,
    activeItem,
    positions,
    isLoading,
  }

  const trackProps = {
    setTrackIsActive,
    trackIsActive,
    setActiveItem,
    activeItem,
    itemWidth,
    positions,
  }

  const itemProps = {
    setTrackIsActive,
    trackIsActive,
    setActiveItem,
    activeItem,
    itemWidth,
    positions,
  }

  return (
    <Flex flexDirection="column" h="calc(100% - 20px)">
      <HStack p="16px">
        <CarouselIndicator
          activeItem={activeItem}
          count={positions.length}
          setActiveItem={setActiveItem}
        />
        {visibleCloseButton && <CloseButton onClick={onFinish} />}
      </HStack>
      <Box flex="1">
        <Slider {...sliderProps}>
          <Track {...trackProps}>
            {children?.map((child, index) => (
              <Item {...itemProps} index={index} key={index}>
                {child}
              </Item>
            ))}
          </Track>
        </Slider>
      </Box>
      <ManagementButtons
        count={positions.length}
        activeItem={activeItem}
        setTrackIsActive={setTrackIsActive}
        setActiveItem={setActiveItem}
        onFinish={onFinish}
      />
    </Flex>
  )
}

export default memo(Carousel)
