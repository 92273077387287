import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PayloadOpen, PopupSchema } from '../types/popupSchema'

const initialState: PopupSchema = {
  isOpen: false,
  variant: 'default',
  type: null,
  props: {},
  header: null,
  footer: null,
  content: '',
  canClose: true,
  visibleCloseButton: true,
}

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    open: (state, { payload }: PayloadAction<PayloadOpen>) => {
      const { onSetCallback, params, variant, canClose = true, visibleCloseButton = true } = payload

      state.isOpen = false
      onSetCallback?.()
      state.type = params.type
      state.canClose = canClose
      state.visibleCloseButton = visibleCloseButton
      if ('props' in params && params?.props) {
        const { props } = params
        state.props = props
        state.header = ('header' in props && props?.header) as string || null
        state.footer = ('footer' in props && props?.footer) as string || null
      }
      else {
        state.header = null
        state.footer = null
      }
      state.variant = variant ? variant : 'default'
      state.isOpen = true
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setFooter: (state, action: PayloadAction<string | null>) => {
      state.footer = action.payload
    },
    setHeader: (state, action: PayloadAction<string | null>) => {
      state.footer = action.payload
    },
    setCanClose: (state, action: PayloadAction<boolean>) => {
      state.canClose = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: popupActions } = popupSlice
export const { reducer: popupReducer } = popupSlice
