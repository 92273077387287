import { forwardRef } from '@chakra-ui/react'
import { memo } from 'react'

import { Icon, IconProps } from '../../ui/Icon'

export type Props = IconProps
const ArrowRightIcon = forwardRef<Props, 'svg'>(function ArrowRightIcon(
  { width = '6px', height = '10px', color = 'primary', ...props },
  ref,
) {
  return (
    <Icon
      viewBox="0 0 6 10"
      w={width}
      h={height}
      color={color}
      fill="none"
      ref={ref}
      {...props}
    >
      <path d="M0.231224 9.76897C0.537478 10.077 1.03253 10.077 1.33878 9.76897L5.94379 5.1373C5.97975 5.10113 6.00001 5.05165 6.00001 5C6.00001 4.94834 5.97975 4.89886 5.94379 4.86269L1.33878 0.231021C1.03253 -0.0770071 0.537477 -0.077007 0.231223 0.231021C-0.0770745 0.541104 -0.0770744 1.04543 0.231223 1.35552L3.85472 5L0.231224 8.64447C-0.0770738 8.95456 -0.0770737 9.45889 0.231224 9.76897Z" fill="#808B9F" />
    </Icon>
  )
})

export default memo(ArrowRightIcon)
