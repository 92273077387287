import React, { FC, PropsWithChildren } from 'react'
import { ArrowRightIcon } from 'shared/icons/ArrowRightIcon'
import { Flex } from 'shared/ui/Flex'
import { Text } from 'shared/ui/Text'

interface RoomCardProps extends PropsWithChildren {
  onClick: (room: number) => void
  room: number
}

const RoomCard: FC<RoomCardProps> = ({ children, onClick, room }) => {
  return (
    <Flex
      boxShadow="0px 2px 4px 0px #0000001F"
      width="100%"
      p="20px"
      borderRadius="12px"
      justify="space-between"
      alignItems="center"
      onClick={() => onClick(room)}
    >
      <Text fontSize="17px" fontWeight={500}>
        {children}
      </Text>
      <ArrowRightIcon />
    </Flex>
  )
}

export default RoomCard
