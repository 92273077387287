import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { debounce } from 'shared/lib/debounce'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Flex } from 'shared/ui/Flex'
import { Text } from 'shared/ui/Text'

import { getToastDelay } from '../model/selectors/getToastDelay/getToastDelay'
import { getToastStatus } from '../model/selectors/getToastStatus/getToastStatus'
import { getToastText } from '../model/selectors/getToastText/getToastText'
import { getToastVisible } from '../model/selectors/getToastVisible/getToastVisible'
import { toastActions } from '../model/slice/toastSlice'

const MotionFlex = motion(Flex)

const changeThemeColor = (color: string) => {
  const metaThemeColor = document.querySelector('meta[name="theme-color"]')
  if (metaThemeColor) {
    (debounce(() => {
      metaThemeColor.setAttribute('content', color)
    }, color === 'white' ? 300 : 0))()
  }
}

const Toast = () => {
  const text = useSelector(getToastText)
  const status = useSelector(getToastStatus)
  const delay = useSelector(getToastDelay)
  const visible = useSelector(getToastVisible)
  const dispatch = useAppDispatch()

  const color = useMemo(() => {
    switch (status) {
      case 'success':
        return '#12A356'
      case 'error':
        return '#FF3B30'
      default:
        return 'white'
    }
  }, [status])

  useEffect(() => {
    changeThemeColor(color)
    let timeoutId: ReturnType<typeof setTimeout>
    if (delay) {
      timeoutId = setTimeout(() => {
        dispatch(toastActions.clearToast())
      }, delay)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [status, color, delay, dispatch, visible])

  return (
    <AnimatePresence>
      {visible && (
        <MotionFlex
          borderRadius="0px 0px 9px 9px"
          backgroundColor={color}
          justifyContent="center"
          alignItems="center"
          initial={{ height: 0 }}
          animate={{ height: '44px' }}
          exit={{ height: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Text color="white">{text}</Text>
        </MotionFlex>
      )}
    </AnimatePresence>
  )
}

export default memo(Toast)
