import { ApartmentPage } from 'pages/ApartmentPage'
import { ApartmentsPage } from 'pages/ApartmentsPage'
import { BestResultPage } from 'pages/BestResultPage'
import { DimensionPage } from 'pages/DimensionPage'
import { EquipmentPage } from 'pages/EquipmentPage'
import { HistoryPage } from 'pages/HistoryPage'
import { LoginPage } from 'pages/LoginPage'
import { NotFoundPage } from 'pages/NotFoundPage'
import { ResultPage } from 'pages/ResultPage'
import { RoomSelectionPage } from 'pages/RoomSelectionPage'
import { RouteProps } from 'react-router-dom'
import { AppRoutes, RoutePath } from 'shared/const/route'

export type AppRoutesProps = RouteProps & {
  authOnly?: boolean
}

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <RoomSelectionPage />,
    authOnly: true,
  },
  [AppRoutes.LOGIN]: {
    path: RoutePath.login,
    element: <LoginPage />,
  },
  [AppRoutes.APARTMENTS]: {
    path: RoutePath.apartments,
    element: <ApartmentsPage />,
    authOnly: true,
  },
  [AppRoutes.APARTMENT]: {
    path: RoutePath.apartment,
    element: <ApartmentPage />,
    authOnly: true,
  },
  [AppRoutes.EQUIPMENT]: {
    path: RoutePath.equipment,
    element: <EquipmentPage />,
    authOnly: true,
  },
  [AppRoutes.DIMENSION]: {
    path: RoutePath.dimension,
    element: <DimensionPage />,
    authOnly: true,
  },
  [AppRoutes.RESULT]: {
    path: RoutePath.result,
    element: <ResultPage />,
    authOnly: true,
  },
  [AppRoutes.HISTORY]: {
    path: RoutePath.history,
    element: <HistoryPage />,
    authOnly: true,
  },
  [AppRoutes.BEST]: {
    path: RoutePath.best,
    element: <BestResultPage />,
    authOnly: true,
  },
  // last
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: <NotFoundPage />,
    authOnly: true,
  },
}
