import { getUserIsAuth } from 'entities/User'
import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { RoutePath } from 'shared/const/route'

interface RequireAuthProps extends PropsWithChildren {}

export function RequireAuth({ children }: RequireAuthProps) {
  const isAuth = useSelector(getUserIsAuth)
  const location = useLocation()

  if (!isAuth) {
    return <Navigate to={RoutePath.login} state={{ from: location }} replace />
  }

  if (location.pathname === RoutePath.login && isAuth) {
    return <Navigate to={RoutePath.main} state={{ from: location }} replace />
  }
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}
