import React, { FC, memo, SetStateAction, useCallback } from 'react'

import { Button } from '../../Button'
import { HStack, VStack } from '../../Stack'

interface ManagementButtonsProps {
  count: number
  setTrackIsActive: (state: SetStateAction<boolean>) => void
  setActiveItem: (state: SetStateAction<number>) => void
  activeItem: number
  onFinish?: () => void
}

const ManagementButtons: FC<ManagementButtonsProps> = ({
  count,
  setTrackIsActive,
  setActiveItem,
  activeItem,
  onFinish,
}) => {
  const handleFocus = () => setTrackIsActive(true)

  const handleIncrementClick = useCallback(() => {
    setTrackIsActive(true)
    !(activeItem === count) && setActiveItem(prev => prev + 1)
  }, [activeItem, count, setTrackIsActive, setActiveItem])

  const handleClose = useCallback(() => {
    onFinish?.()
  }, [onFinish])

  return (
    <VStack p="16px" pt={0} gap="5px" w="100%">
      <HStack w="100%">
        {activeItem !== count - 1 && (
          <Button size="md" w="100%" onClick={handleIncrementClick} onFocus={handleFocus}>
            Далее
          </Button>
        )}

        {activeItem === count - 1 && (
          <Button size="md" w="100%" onClick={handleClose} onFocus={handleFocus}>
            Отлично
          </Button>
        )}
      </HStack>
    </VStack>
  )
}

export default memo(ManagementButtons)
