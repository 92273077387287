import { LogoutButton } from 'entities/User'
import { BackButton } from 'features/backButton'
import { OpenOnboardingButton } from 'features/onboarding'
import { matchPath, useLocation } from 'react-router-dom'
import { AppRoutes, PageName, RoutePath } from 'shared/const/route'
import { PAGE_PADDING } from 'shared/const/style'
import { Flex } from 'shared/ui/Flex'
import { HStack } from 'shared/ui/Stack'
import { Text } from 'shared/ui/Text'

const Navbar = () => {
  const location = useLocation()
  const currentRoute = Object.keys(RoutePath).find((key) => {
    const path = RoutePath[key as AppRoutes]
    return matchPath(path, location.pathname)
  })
  const pageName = PageName[currentRoute as AppRoutes]

  return (
    <HStack
      borderBottom="1px solid"
      borderColor="brand.gray.400"
      maxH="44px"
      h="44px"
      px={PAGE_PADDING + 'px'}
      pr={0}
      alignItems="center"
      justify="space-between"
    >
      <Flex w="100%" h="100%" justify="start" alignItems="center">
        <BackButton />
      </Flex>
      <Flex
        w="100%"
        h="100%"
        justify={{
          'base': 'start',
          'md': 'center',
          'lg': 'center',
          'xl': 'center',
          '2xl': 'center',
        }}
        alignItems="center"
      >
        <Text
          w="100%"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          fontSize="16px"
          align="center"
          fontWeight="bold"
        >
          {pageName}
        </Text>
      </Flex>
      <Flex w="100%" h="100%" justify="end" alignItems="center">
        <OpenOnboardingButton h="100%" />
        <LogoutButton h="100%" />
      </Flex>
    </HStack>
  )
}

export default Navbar
