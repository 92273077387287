import { forwardRef } from '@chakra-ui/react'
import { memo } from 'react'

import { Icon, IconProps } from '../../ui/Icon'

export type Props = IconProps
const InfoIcon = forwardRef<Props, 'svg'>(function InfoIcon(
  { width = '20px', height = '20px', color = 'primary', ...props },
  ref,
) {
  return (
    <Icon
      viewBox="0 0 20 20"
      w={width}
      height={height}
      color={color}
      fill="none"
      ref={ref}
      {...props}
    >
      <path d="M11 5V7H9V5H11Z" fill="#FE5000" />
      <path d="M11 15V9H9V15H11Z" fill="#FE5000" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#FE5000" />
    </Icon>
  )
})

export default memo(InfoIcon)
