import {
  forwardRef,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  MenuButtonProps as ChakraMenuButtonProps,
  MenuDivider as ChakraMenuDivider,
  MenuDividerProps as ChakraMenuDividerProps,
  MenuGroup as ChakraMenuGroup,
  MenuGroupProps as ChakraMenuGroupProps,
  MenuItem as ChakraMenuItem,
  MenuItemOption as ChakraMenuItemOption,
  MenuItemOptionProps as ChakraMenuItemOptionProps,
  MenuItemProps as ChakraMenuItemProps,
  MenuList as ChakraMenuList,
  MenuListProps as ChakraMenuListProps,
  MenuOptionGroup as ChakraMenuOptionGroup,
  MenuOptionGroupProps as ChakraMenuOptionGroupProps,
  MenuProps as ChakraMenuProps,
} from '@chakra-ui/react'
import { FC } from 'react'

export type MenuProps = ChakraMenuProps
export const Menu: FC<MenuProps> = (props) => {
  return <ChakraMenu {...props}>{props.children}</ChakraMenu>
}

export type MenuButtonProps = ChakraMenuButtonProps
export const MenuButton = forwardRef<MenuButtonProps, 'button'>(
  function MenuButton(props, ref) {
    return <ChakraMenuButton {...props} ref={ref} />
  },
)

export type MenuListProps = ChakraMenuListProps
export const MenuList = forwardRef<MenuListProps, 'div'>(function MenuButton(
  props,
  ref,
) {
  return <ChakraMenuList {...props} ref={ref} />
})

export type MenuItemProps = ChakraMenuItemProps
export const MenuItem = forwardRef<MenuItemProps, 'button'>(function MenuItem(
  props,
  ref,
) {
  return <ChakraMenuItem {...props} ref={ref} />
})

export type MenuGroupProps = ChakraMenuGroupProps
export const MenuGroup = forwardRef<MenuGroupProps, 'div'>(function MenuGroup(
  props,
  ref,
) {
  return <ChakraMenuGroup {...props} ref={ref} />
})

export type MenuOptionGroupProps = ChakraMenuOptionGroupProps
export const MenuOptionGroup: FC<MenuOptionGroupProps> = (props) => {
  return <ChakraMenuOptionGroup {...props} />
}

export type MenuDividerProps = ChakraMenuDividerProps
export const MenuDivider: FC<MenuDividerProps> = (props) => {
  return <ChakraMenuDivider {...props} />
}

export type MenuItemOptionProps = ChakraMenuItemOptionProps
export const MenuItemOption: FC<MenuItemOptionProps> = (props) => {
  return <ChakraMenuItemOption {...props} />
}
