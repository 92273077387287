import { createSlice } from '@reduxjs/toolkit'

import { ApartmentsSchema } from '../types/ApartmentsSchema'

const initialState: ApartmentsSchema = {
  seletctedApartmentId: null,
}

export const apartmentsSlice = createSlice({
  name: 'apartments',
  initialState,
  reducers: {
    setSeletctedApartmentId: (state, action) => {
      state.seletctedApartmentId = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: apartmentsActions } = apartmentsSlice
export const { reducer: apartmentsReducer } = apartmentsSlice
