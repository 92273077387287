import { Skeleton } from '@chakra-ui/react'
import React, { FC, memo } from 'react'
import { Breakpoint } from 'shared/const/breakpoints'
import { useIsBreakpoint } from 'shared/lib/hooks/useIsBreakpoint'

const ApartmentsListSkeleton: FC = () => {
  const is2xl = useIsBreakpoint(Breakpoint['2xl'])
  const data = is2xl ? [1, 2, 3, 4, 5, 6, 7, 8] : [1, 2, 3]
  return (
    <React.Fragment>
      {data.map((_, index) => (
        <Skeleton
          key={index}
          w="100%"
          h="266px"
          borderRadius="12px"
          p="5px"
          maxH="266px"
        />
      ))}
    </React.Fragment>
  )
}

export default memo(ApartmentsListSkeleton)
