import {
  forwardRef,
  Progress as ChakraProgress,
  ProgressProps as ChakraProgressProps,
} from '@chakra-ui/react'
import { FC } from 'react'

import { Flex } from '../Flex'
import { Text } from '../Text'

export type BaseProgressProps = ChakraProgressProps
export const BaseProgress = forwardRef<BaseProgressProps, 'div'>(
  function BaseProgress(props, ref) {
    return <ChakraProgress ref={ref} {...props} />
  },
)

export type ProgressProps = {
  trackTransition?: number
} & BaseProgressProps

export const Progress: FC<ProgressProps> = (props) => {
  return (
    <Flex gap="20px" w="100%" flexDirection="column">
      <BaseProgress
        height="6px"
        {...props}
      />
      <Text textAlign="center">{props.value + '%'}</Text>
    </Flex>
  )
}
