import { enhancedStore } from 'app/providers/storeProvider'

import { dimensionActions } from '../../slice/dimensionSlice'
import { DIMENSION } from '../../store/DIMENSION'

export const countingResults = () => {
  // нужно подождать пока весь синхронный код с расчетами выполниться сделаеи лоудер что подсчитываем
  setTimeout(() => {
    if (DIMENSION.canvasElementHotmap) {
      const image = DIMENSION.canvasElementHotmap.toDataURL('image/png')
      enhancedStore.dispatch(dimensionActions.setImageHotmap(image))
    }
  }, 3000)
}
