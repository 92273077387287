import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  forwardRef,
} from '@chakra-ui/react'

export type Props = ChakraFormControlProps

const FormControl = forwardRef<Props, 'div'>(function FormControl(props, ref) {
  return <ChakraFormControl ref={ref} {...props} />
})

export default FormControl
