import {
  combineReducers, Reducer, ReducersMapObject,
  UnknownAction } from '@reduxjs/toolkit'

import { ReducerManager, StateSchema, StateSchemaKey } from '../types/StateSchema'

export function createReducerManager(initialReducers: ReducersMapObject<StateSchema>): ReducerManager {
  const reducers = { ...initialReducers }

  let combinedReducer = combineReducers(reducers)

  let keysToRemove: StateSchemaKey[] = []

  return {
    getReducerMap: () => reducers,
    reduce: (state: StateSchema | undefined, action: UnknownAction) => {
      if (state === undefined) {
        // Если состояние не определено, вернем пустой объект
        return {} as StateSchema
      }
      if (keysToRemove.length > 0) {
        // Создаем новый объект состояния без указанных ключей
        const nextState: StateSchema = {} as StateSchema
        Object.keys(state).forEach((key) => {
          if (!keysToRemove.includes(key as StateSchemaKey)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            nextState[key as StateSchemaKey] = state[key as StateSchemaKey]
          }
        })
        keysToRemove = [] // Очищаем список ключей для удаления
        return nextState
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return combinedReducer(state, action)
    },
    add: (key: StateSchemaKey, reducer: Reducer) => {
      if (!key || reducers[key]) {
        return
      }
      reducers[key] = reducer

      combinedReducer = combineReducers(reducers)
    },
    get: (key: StateSchemaKey) => {
      if (!key || !reducers[key]) {
        return
      }
      return reducers[key]
    },
    isReducerExists: (key: StateSchemaKey) => {
      return !!reducers[key]
    },
    remove: (key: StateSchemaKey) => {
      if (!key || !reducers[key]) {
        return
      }
      // Создаем новый объект без указанного ключа
      const { [key]: _, ...newReducers } = reducers
      keysToRemove.push(key)
      combinedReducer = combineReducers(newReducers as ReducersMapObject<StateSchema>)
    },
  }
}
