import { DIMENSION } from '../../store/DIMENSION'

export const clearDimensions = () => {
  DIMENSION.Rx = [0, 0, 0]
  DIMENSION.Ry = [0, 0, 0]

  DIMENSION.PFm = []
  DIMENSION.PFc = []

  DIMENSION.PFz = []
  DIMENSION.PFvz = []
  DIMENSION.PFaz = []
  DIMENSION.PFheatmap = []

  DIMENSION.Xcanvas = 0
  DIMENSION.Ycanvas = 0
  DIMENSION.takeover = 0
  DIMENSION.NImgx = 0
  DIMENSION.NImgy = 0
  DIMENSION.Nx = 0
  DIMENSION.Ny = 0
  DIMENSION.X0 = 0
  DIMENSION.Y0 = 0
  DIMENSION.Wd = 0
  DIMENSION.Hg = 0
  DIMENSION.PFm2 = []
  DIMENSION.PFc2 = []
  DIMENSION.end = false
  DIMENSION.PCounter = -1
  DIMENSION.displayWaves = false
  DIMENSION.ctx = undefined
  DIMENSION.canvasElement = undefined
  DIMENSION.imgData = undefined
  DIMENSION.ctx_imgData = undefined
  DIMENSION.ctx_hotmap = undefined
  DIMENSION.canvasElementHotmap = undefined
  DIMENSION.imgData_hotmap = undefined
  DIMENSION.ctx_imgData_hotmap = undefined

  DIMENSION.stop = true

  DIMENSION.Counter = 0

  setTimeout(() => clearInterval(DIMENSION.interval), 100)
}
