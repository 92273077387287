import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { BestResultRequest, BestResultResponse } from '../../types/BestResult'

export const bestResultApi = createApi({
  reducerPath: 'bestResultApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getBestResult: builder.query<BestResultResponse, BestResultRequest>({
      query: ({ id, params }) => {
        return {
          url: `/coverage/${id}`,
          params,
        }
      },
    }),
  }),
})

export const { useGetBestResultQuery } = bestResultApi
