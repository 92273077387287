import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ToastSchema } from '../types/toast'

interface PayloadSetToast {
  delay: number
  text: string
  status: '' | 'success' | 'error'
}

const initialState: ToastSchema = {
  status: '',
  text: '',
  delay: 0,
  visible: false,
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action: PayloadAction<PayloadSetToast>) => {
      state.delay = action.payload.delay
      state.text = action.payload.text
      state.status = action.payload.status
      state.visible = true
    },
    clearToast: (state) => {
      state.delay = 0
      state.text = ''
      state.status = ''
      state.visible = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: toastActions } = toastSlice
export const { reducer: toastReducer } = toastSlice
