import { motion, PanInfo, useAnimation, useMotionValue } from 'framer-motion'
import { FC, memo, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import { Flex } from '../../Flex'
import { VStack } from '../../Stack'

const transitionProps = {
  stiffness: 400,
  type: 'spring',
  damping: 60,
  mass: 3,
}

const MotionFlex = motion(Flex)

interface TrackProps {
  setTrackIsActive: (state: SetStateAction<boolean>) => void
  trackIsActive: boolean
  setActiveItem: (state: SetStateAction<number>) => void
  activeItem: number
  itemWidth: number
  positions: number[]
  children?: ReactNode
}

const Track: FC<TrackProps> = ({
  activeItem,
  itemWidth,
  positions,
  children,
  setActiveItem,
}) => {
  const [dragStartPosition, setDragStartPosition] = useState(0)
  const controls = useAnimation()
  const x = useMotionValue(0)
  const node = useRef(null)
  const multiplier = 0.35

  const handleDragStart = () => setDragStartPosition(positions[activeItem])
  const handleDragEnd = (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const distance = info.offset.x
    const velocity = info.velocity.x * multiplier
    const direction = velocity < 0 || distance < 0 ? 1 : -1

    const extrapolatedPosition
      = dragStartPosition
      + (direction === 1
        ? Math.min(velocity, distance)
        : Math.max(velocity, distance))

    const closestPosition = positions.reduce((prev, curr) => {
      return Math.abs(curr - extrapolatedPosition)
        < Math.abs(prev - extrapolatedPosition)
        ? curr
        : prev
    }, 0)

    if (!(closestPosition < positions[positions.length])) {
      setActiveItem(positions.indexOf(closestPosition))
      controls.start({
        x: closestPosition,
        transition: {
          velocity: info.velocity.x,
          ...transitionProps,
        },
      })
    }
    else {
      setActiveItem(positions.length)
      controls.start({
        x: positions[positions.length],
        transition: {
          velocity: info.velocity.x,
          ...transitionProps,
        },
      })
    }
  }

  const handleResize = useCallback(
    () =>
      controls.start({
        x: positions[activeItem],
        transition: {
          ...transitionProps,
        },
      }),
    [activeItem, controls, positions],
  )

  useEffect(() => {
    handleResize()
  }, [handleResize])

  return (
    <>
      {itemWidth && (
        <VStack w="100%" h="100%" ref={node} alignItems="stretch">
          <MotionFlex
            dragConstraints={node}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            animate={controls}
            style={{ x }}
            drag="x"
            _active={{ cursor: 'grabbing' }}
            minWidth="min-content"
            flexWrap="nowrap"
            cursor="grab"
            h="100%"
          >
            {children}
          </MotionFlex>
        </VStack>
      )}
    </>
  )
}

export default memo(Track)
