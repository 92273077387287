import { enhancedStore } from 'app/providers/storeProvider'

import { dimensionActions } from '../../../slice/dimensionSlice'
import { DIMENSION } from '../../../store/DIMENSION'

const colorsPixels = {
  green: 0,
  gray: 0,
  yellow: 0,
}

let index = 0

const getColorForHP = (hp: number) => {
  if (hp <= 80) {
    colorsPixels.gray++
    return [238, 238, 238, 160]
  }
  else if (hp <= 120) {
    colorsPixels.yellow++
    const ratio = (hp - 120) / (80 - 0)
    return [
      255, // Red (постоянно 255)
      Math.round(255 * 3 * (0.66 - ratio)), // Green (от желтого 255 к серому 128)
      Math.round(0 * (1 - ratio)), // Blue (от желтого 0 к серому 128)
      255,
    ]
  }
  else {
    colorsPixels.green++
    const ratio = (hp - 80) / (120 - 0)
    return [
      Math.round(255 * 3 * (0.66 - ratio)), // Red (от зеленого 0 к желтому 255)
      255, // Green (постоянно 255)
      0, // Blue (от зеленого 0 к желтому 0)
      255,
    ]
  }
}

export const displayHotmap = () => {
  if (!DIMENSION.ctx_imgData_hotmap) return
  for (let y = 0; y < DIMENSION.NImgy; y++) {
    for (let q = 0; q < 2; q++) {
      for (let x = 0; x < DIMENSION.NImgx; x++) {
        for (let r = 0; r < 2; r++) {
          let hp = Math.round(
            2
            * (DIMENSION.PFheatmap[x + DIMENSION.takeover / 2][
              y + DIMENSION.takeover / 2
            ]
            + 80),
          )
          if (hp < 0) {
            hp = 0
          }
          if (hp > 255) {
            hp = 255
          }
          if (DIMENSION.ctx_imgData_hotmap[index] > 240) {
            const [rColor, gColor, bColor, alpha] = getColorForHP(hp)
            DIMENSION.ctx_imgData_hotmap[index++] = rColor
            DIMENSION.ctx_imgData_hotmap[index++] = gColor
            DIMENSION.ctx_imgData_hotmap[index++] = bColor
            DIMENSION.ctx_imgData_hotmap[index++] = alpha
          }
          else {
            index += 4
          }
        }
      }
    }
  }
  const percentages: Record<string, number> = {
    green: 0,
    yellow: 0,
    gray: 0,
  }
  const totalPixels = colorsPixels.green + colorsPixels.yellow + colorsPixels.gray

  // Вычисляем процентные значения без округления
  const rawPercentages = {
    green: (colorsPixels.green / totalPixels) * 100,
    yellow: (colorsPixels.yellow / totalPixels) * 100,
    gray: (colorsPixels.gray / totalPixels) * 100,
  }

  // Сумма необработанных процентов
  const totalRawPercentage = rawPercentages.green + rawPercentages.yellow + rawPercentages.gray

  // Корректируем проценты, чтобы сумма была ровно 100
  const adjustment = 100 - totalRawPercentage

  // Найдем минимальное значение и корректируем его
  const minValue = Math.min(rawPercentages.green, rawPercentages.yellow, rawPercentages.gray)
  if (rawPercentages.green === minValue) rawPercentages.green += adjustment
  else if (rawPercentages.yellow === minValue) rawPercentages.yellow += adjustment
  else rawPercentages.gray += adjustment

  // Округляем значения для использования
  percentages.green = Math.round(rawPercentages.green)
  percentages.yellow = Math.round(rawPercentages.yellow)
  percentages.gray = Math.round(rawPercentages.gray)

  enhancedStore.dispatch(
    dimensionActions.setResultGradient({
      strong: percentages.green,
      average: percentages.yellow,
      weak: percentages.gray,
    }),
  )
}
