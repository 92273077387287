import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { OnboardingCompleteRequest, OnboardingCompleteResponse } from '../../types/onboardingTypes'

export const onboardingCompleteApi = createApi({
  reducerPath: 'onboardingCompleteApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    complete: builder.mutation<OnboardingCompleteResponse, OnboardingCompleteRequest>({
      query: () => ({
        url: '/onboarding/complete',
        method: 'POST',
      }),
    }),
  }),
})

export const { useCompleteMutation } = onboardingCompleteApi
