import React, { FC, memo, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  DynamicModuleLoader,
  ReducersList,
} from 'shared/lib/components/DynamicModuleLoader'
import { getQueryParams } from 'shared/lib/getQueryParams/getQueryParams'

import ApartmentsListItem from './ApartmentsListItem/ApartmentsListItem'
import ApartmentsListSkeleton from './Skeleton/ApartmentsListSkeleton'

import {
  apartmentsApi,
  useGetApartmentsListQuery,
} from '../../model/service/getApartmentsList/getApartmentsList'
import { apartmentsReducer } from '../../model/slice/apartmentsSlice'

const initialReducers: ReducersList = {
  [apartmentsApi.reducerPath]: apartmentsApi.reducer,
  apartments: apartmentsReducer,
}

interface ApartmentsListProps {
  handleClickNext: (apartmentId: number) => void
}

const ApartmentsList: FC<ApartmentsListProps> = ({ handleClickNext }) => {
  const [searchParams] = useSearchParams()

  const queryParams = getQueryParams(searchParams, {
    requiredQueryParams: { numberRooms: '1' },
  })

  const { data, isFetching } = useGetApartmentsListQuery(queryParams) || {}

  const content = useMemo(() => {
    if (isFetching) {
      return <ApartmentsListSkeleton />
    }
    return data?.data?.list?.map(({ id, url, squareMeters, numberRooms }) => {
      return (
        <ApartmentsListItem
          key={id}
          id={id}
          url={url}
          squareMeters={squareMeters}
          numberRooms={numberRooms}
          onClick={handleClickNext}
        />
      )
    })
  }, [isFetching, data, handleClickNext])

  return (
    <DynamicModuleLoader reducers={initialReducers}>
      {content}
    </DynamicModuleLoader>
  )
}

export default memo(ApartmentsList)
