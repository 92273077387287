import borders from './borders'
import colors from './colors'
import radii from './radius'
import space from './space'

export const foundations = {
  radii,
  colors,
  space,
  borders,
}
