import React, { FC } from 'react'
import { Flex } from 'shared/ui/Flex'

import { useGetUserInfoQuery } from '../../model/service/userProfile/userProfile'

export interface UserProfilePopupProps {}

const UserProfilePopup: FC<UserProfilePopupProps> = () => {
  const { data } = useGetUserInfoQuery({})

  return (
    <Flex
      flexDirection="column"
    >
      username:
      {' '}
      {data?.data.profile.username}
      Роли:
      {' '}
      {data?.data.profile.roles}
    </Flex>
  )
}

export default UserProfilePopup
