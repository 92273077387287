import { getUserInited, userActions } from 'entities/User'
import { Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withZoomDisabled } from 'shared/hocs/withZoomDisabled'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { Loader } from 'shared/ui/Loader'

import { AppRouter } from './providers/router'

function App() {
  const inited = useSelector(getUserInited)
  const dispath = useAppDispatch()
  useEffect(() => {
    dispath(userActions.initAuthData())
  }, [dispath])
  return (
    <Suspense fallback={<Loader withWrapper />}>
      {inited && <AppRouter />}
    </Suspense>
  )
}

export default withZoomDisabled(App)
