import React, { FC, memo, useEffect, useRef } from 'react'
import { Flex } from 'shared/ui/Flex'

import { countingResults } from '../../model/service/startListenersDimension/countingResults'
import { DIMENSION } from '../../model/store/DIMENSION'

interface HotmapCanvasProps {

}

const HotmapCanvas: FC<HotmapCanvasProps> = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!canvasRef || !canvasRef.current) {
      return
    }

    const currCtx = canvasRef.current.getContext('2d', { willReadFrequently: true })

    if (!currCtx) {
      return
    }

    currCtx.canvas.width = DIMENSION.Xcanvas
    currCtx.canvas.height = DIMENSION.Ycanvas
    currCtx.lineWidth = 1

    DIMENSION.ctx_hotmap = currCtx
    DIMENSION.canvasElementHotmap = canvasRef.current
    countingResults()
  }, [canvasRef])
  return (
    <Flex mt={5} w="100%" h="100%" flexDirection="column" justifyContent="center">
      <Flex
        justifyContent="center"
      >
        <canvas ref={canvasRef} />
      </Flex>
    </Flex>
  )
}

export default memo(HotmapCanvas)
