import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'shared/lib/baseQueryWithReauth'

import { ApartmentsListRequest, ApartmentsListResponse } from '../../types/ApartmentsTypes'

export const apartmentsApi = createApi({
  reducerPath: 'apartmentsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getApartmentsList: builder.query<ApartmentsListResponse, ApartmentsListRequest>({
      query: (params) => {
        return {
          url: '/flat',
          params,
        }
      },
    }),
  }),
})

export const { useGetApartmentsListQuery } = apartmentsApi
