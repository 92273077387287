import { forwardRef } from '@chakra-ui/react'
import { memo } from 'react'

import { Icon, IconProps } from '../../ui/Icon'

export type Props = IconProps
const RouterIcon = forwardRef<Props, 'svg'>(function RouterIcon(
  { width = '32px', height = '32px', color = 'primary', ...props },
  ref,
) {
  return (
    <Icon
      viewBox="0 0 32 32"
      w={width}
      h={height}
      color={color}
      fill="none"
      ref={ref}
      {...props}
    >
      <g clipPath="url(#clip0_1318_1434)">
        <path d="M16 28.0002C15.0667 28.0002 14.2778 27.6779 13.6333 27.0335C12.9889 26.3891 12.6667 25.6002 12.6667 24.6668C12.6667 23.7335 12.9889 22.9446 13.6333 22.3002C14.2778 21.6557 15.0667 21.3335 16 21.3335C16.9333 21.3335 17.7222 21.6557 18.3667 22.3002C19.0111 22.9446 19.3333 23.7335 19.3333 24.6668C19.3333 25.6002 19.0111 26.3891 18.3667 27.0335C17.7222 27.6779 16.9333 28.0002 16 28.0002ZM8.46667 20.4668L5.66667 17.6002C6.97778 16.2891 8.51689 15.2504 10.284 14.4842C12.0511 13.7179 13.9564 13.3344 16 13.3335C18.0436 13.3326 19.9493 13.7215 21.7173 14.5002C23.4853 15.2788 25.024 16.3344 26.3333 17.6668L23.5333 20.4668C22.5556 19.4891 21.4222 18.7224 20.1333 18.1668C18.8444 17.6113 17.4667 17.3335 16 17.3335C14.5333 17.3335 13.1556 17.6113 11.8667 18.1668C10.5778 18.7224 9.44444 19.4891 8.46667 20.4668ZM2.8 14.8002L0 12.0002C2.04444 9.91127 4.43333 8.27794 7.16667 7.10016C9.9 5.92238 12.8444 5.3335 16 5.3335C19.1556 5.3335 22.1 5.92238 24.8333 7.10016C27.5667 8.27794 29.9556 9.91127 32 12.0002L29.2 14.8002C27.4889 13.0891 25.5058 11.7504 23.2507 10.7842C20.9956 9.81794 18.5787 9.33438 16 9.3335C13.4213 9.33261 11.0049 9.81616 8.75067 10.7842C6.49644 11.7522 4.51289 13.0908 2.8 14.8002Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_1318_1434">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  )
})

export default memo(RouterIcon)
