import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { Box } from 'shared/ui/Box'
import { Flex } from 'shared/ui/Flex'
import { HStack } from 'shared/ui/Stack'
import { Text } from 'shared/ui/Text'

import {
  getHotmapAverage,
  getHotmapStrong,
  getHotmapWeak,
} from '../../model/selectors/getHotmapResult/getHotmapResult'

interface HotmapResultProps {
  visibleOnlyProgress?: boolean
}

const HotmapResult: FC<HotmapResultProps> = ({
  visibleOnlyProgress = false,
}) => {
  const strong = useSelector(getHotmapStrong)
  const average = useSelector(getHotmapAverage)
  const weak = useSelector(getHotmapWeak)

  return (
    <Flex align="center" mt="30px" h="50px" maxH="50px" justifyContent="center" flexDirection="column">
      <HStack w="100%">
        {strong && (
          <Box
            w={`${strong}%`}
            h="12px"
            borderRadius="64px"
            backgroundColor="brand.green.200"
            position="relative"
          >
            {strong > 15 && (
              <Text
                fontSize="15px"
                w="100%"
                align="center"
                fontWeight="500"
                position="absolute"
                bottom="15px"
              >
                {strong}
                %
              </Text>
            )}
          </Box>
        )}
        {average && (
          <Box
            w={`${average}%`}
            h="12px"
            borderRadius="64px"
            backgroundColor="brand.yellow"
            position="relative"
          >
            {average > 15 && (
              <Text
                fontSize="15px"
                w="100%"
                align="center"
                fontWeight="500"
                position="absolute"
                bottom="15px"
              >
                {average}
                %
              </Text>
            )}
          </Box>
        )}
        {weak && (
          <Box
            w={`${weak}%`}
            h="12px"
            borderRadius="64px"
            backgroundColor="brand.gray.500"
            position="relative"
          >
            {weak > 15 && (
              <Text
                fontSize="15px"
                w="100%"
                align="center"
                fontWeight="500"
                position="absolute"
                bottom="15px"
              >
                {weak}
                %
              </Text>
            )}
          </Box>
        )}
      </HStack>
      {!visibleOnlyProgress && (
        <Flex w="100%" justifyContent="space-between" color="brand.gray.600">
          <Text fontSize="11px" fontWeight="700">— 35 DBM</Text>
          <Text fontSize="11px" fontWeight="700">— 95 DBM</Text>
        </Flex>
      )}
    </Flex>
  )
}

export default memo(HotmapResult)
